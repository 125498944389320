import { useRef } from 'react';
import { copyTextToClipboard } from 'shared/utils/copyText';
import { useStore } from 'effector-react';
import { $orderData } from 'models/Order';

const useLocation = () => {
  const locationPopupRef = useRef(null);
  const data = useStore($orderData);

  const onCopyAddress = async () => {
    locationPopupRef.current.style.opacity = 1;
    setTimeout(() => {
      locationPopupRef.current.style.opacity = 0;
    }, 2000);
    await copyTextToClipboard(data.orderData.deliveryAddress);
  };

  return {
    locationPopupRef,
    onCopyAddress,
  };
};

export default useLocation;
