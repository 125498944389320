export const OrdersTableTitles = {
  dispatcher: {
    narrowed: [
      '№ заказа',
      'Сумма',
      'Время заказа',
      'Когда приготовить',
      'Доставка / Самовывоз',
      'Источник',
      'Статус',
    ],
    normal: [
      '№ заказа',
      'Сумма',
      'Ресторан',
      'Время заказа',
      'Когда приготовить',
      'Доставка / Самовывоз',
      'Источник',
      'Статус',
      'URL',
    ],
  },
  operator: {
    narrowed: [
      '№ заказа',
      'Сумма',
      'Время заказа',
      'Когда приготовить',
      'Доставка / Самовывоз',
      'Источник',
      'Статус',
    ],
    normal: [
      '№ заказа',
      'Сумма',
      'Время заказа',
      'Когда приготовить',
      'Доставка / Самовывоз',
      'Источник',
      'Статус',
      'URL',
    ],
  },
};

export const OrderTableTitles = ['Состав заказа', 'Кол.', 'Итого'];

export const ArchiveTableTitles = {
  dispatcher: {
    narrowed: ['№ заказа', 'Сумма', 'Ресторан', 'Время заказа', 'Доставка / Самовывоз', 'URL'],
    normal: ['№ заказа', 'Сумма', 'Ресторан', 'Время заказа', 'Доставка / Самовывоз', 'Источник', 'URL'],
  },
  operator: {
    narrowed: ['№ заказа', 'Сумма', 'Время заказа', 'Доставка / Самовывоз', 'URL'],
    normal: ['№ заказа', 'Сумма', 'Время заказа', 'Доставка / Самовывоз', 'Источник', 'URL'],
  },
};

export const StopListTableTitles = {
  stopped: ['Баркод', 'Блюдо', 'Время добавления', 'Стоп лист', 'Проверить на наличие', 'На стопе'],
  all: ['Баркод', 'Блюдо', 'В стоп'],
};
