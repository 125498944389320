import { v4 as uuidv4 } from 'uuid';
import { sha256 } from 'js-sha256';
import { sha512 } from 'js-sha512';
import { LoginFormFields, LoginPayload } from './interfaces';
import { Navigate } from 'react-router-dom';

export const LoginDTO = (data: LoginFormFields): LoginPayload => {
  const { login, password } = data;
  if (login && password) {
    const salt = uuidv4();
    const passwordHash = sha256(password);
    const sign = sha512.hmac(passwordHash + salt, login);
    return { Login: login ?? '', Salt: salt ?? '', Sign: sign ?? '' };
  }
  return { Login: '', Salt: '', Sign: '' };
};

export const loginRedirect = (navigate: any) => {
  const path = '/hub/orders';
  //console.log(navigate);
  return navigate(path, { replace: true });
};