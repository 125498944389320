import {memo} from 'react';
import { format } from 'date-fns';
import styles from './Caption.module.scss';
import { ReactComponent as LeftBlackArrow } from 'assets/icons/LeftBlackArrow.svg';
import { ReactComponent as RightBlackArrow } from 'assets/icons/RightBlackArrow.svg';
import ru from 'date-fns/locale/ru';
import { capitalizeFirstLetter } from './utils';
import { useDayPicker, useNavigation } from 'react-day-picker';

const Caption = (props) => {
  const { onMonthChange } = useDayPicker();
  const { previousMonth, nextMonth, goToMonth } = useNavigation();

  const previousMonthClick = () => {
    goToMonth(previousMonth);
    onMonthChange(previousMonth);
  };

  const nextMonthClick = () => {
    goToMonth(nextMonth);
    onMonthChange(nextMonth);
  };

  return (
    <div className={styles.container}>
      <LeftBlackArrow className={styles.arrow} onClick={previousMonthClick} />
      {capitalizeFirstLetter(format(props.displayMonth, 'LLLL yyy', { locale: ru }))}
      <RightBlackArrow className={styles.arrow} onClick={nextMonthClick} />
    </div>
  );
};

export default memo(Caption);