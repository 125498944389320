import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { getOrderById } from 'shared/api/Signal/Order';
import { sendToAloha, sendToWhiteCabs } from 'shared/api/REST/OrderApi';
import { StopListSorting } from 'models/StopList/utils';

export const orderIdGate = createGate<number>();

export const getOrderByIdFx = createEffect<any, void>(getOrderById);
export const sendToAlohaFx = createEffect<number, void>(sendToAloha);
export const sendToWhiteCabsFx = createEffect<number, void>(sendToWhiteCabs);

export const enableAlohaButton = createEvent<void>();
export const enableStatusButton = createEvent<void>();
export const enableWhiteCabsButton = createEvent<void>();
export const onSendToAloha = createEvent<void>();
export const onSendToWhiteCabs = createEvent<void>();
export const setForceNextStatus = createEvent();
export const setNextStatus = createEvent();
export const setOrderData = createEvent<any>();
export const setOrderSorting = createEvent<any>();

export const $isDisableAlohaButton = createStore<number | null>(null)
  .on(enableAlohaButton, () => null);
export const $isDisableStatusButton = createStore<number | null>(null)
  .on(enableStatusButton, () => null);
export const $isDisableWhiteCabsButton = createStore<number | null>(null)
  .on(enableWhiteCabsButton, () => null);
export const $orderData = createStore<any | null>(null).on(setOrderData, (_, data) => data);
export const $orderId = createStore<number>(0);
export const $orderSorting = createStore<any>(StopListSorting);
//.on(setOrderSorting, (_, sorting) => sorting);
