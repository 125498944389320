import styles from './SearchIcons.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';

const SearchIcons = ({ onFlushInput, query }) => {
  return (
    <>
      {query.length > 0 ? (
        <CloseIcon className={styles.icon} onClick={onFlushInput} />
      ) : (
        <SearchIcon className={styles.icon} />
      )}
    </>
  );
};

export default SearchIcons;
