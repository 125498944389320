import { useEffect, useRef, useState } from 'react';

const useCalendar = () => {
  const inputRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (inputRef.current && inputRef.current.contains(e.target as Node)) {
        setVisibility(true);
      }

      if (calendarRef.current && calendarRef.current.contains(e.target as Node)) {
        setVisibility(true);
      }

      if (calendarRef.current && inputRef.current && !calendarRef.current.contains(e.target as Node) && !inputRef.current.contains(e.target as Node)) {
        setVisibility(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  return { inputRef, isVisible, calendarRef, setVisibility };
};

export default useCalendar;