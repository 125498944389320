import { useEffect, useRef } from 'react';

const useOrderPopup = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (listRef.current && !containerRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'hidden';
      }

      if (containerRef.current && containerRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'visible';
      }

      if (listRef.current && listRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'hidden';
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  return { containerRef, listRef };
};

export default useOrderPopup;