import { FC, memo } from 'react';
import { TableProps } from './interfaces/ITable';
import styles from './styles/Table.module.scss';
import { ReactComponent as DownArrowIcon } from 'assets/icons/shared/Table/DownArrow.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/shared/Table/UpArrow.svg';
import { useStore } from 'effector-react';

const Table: FC<TableProps> = ({
                                 changeSorting,
                                 markup,
                                 rows,
                                 storeSorting,
                                 titles,
                               }) => {

  const sorting = useStore(storeSorting);

  return (
    <div className={styles.container}>
      <div className={styles.titles} style={{ gridTemplateColumns: markup }}>
        {titles.map((title, cellIndex) => (
          <div className={styles.titleContainer} onClick={() => changeSorting({
            cellIndex,
            type: sorting.type === 'Descending' ? 'Ascending' : 'Descending',
          })}>
            <p className={styles.title}>{title}</p>
            {(sorting.cellIndex === cellIndex) && sorting.type === 'Descending' && <DownArrowIcon />}
            {(sorting.cellIndex === cellIndex) && sorting.type === 'Ascending' && <UpArrowIcon />}
          </div>
        ))}
      </div>
      {rows}
    </div>
  );
};

export default memo(Table);
