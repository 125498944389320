import { ReactComponent as SearchIcon } from 'assets/icons/StopList/Search.svg';
import styles from './SearchAvailability.module.scss';

const SearchAvailability = () => {
  return (
    <div className={styles.container}>
      <SearchIcon />
    </div>
  );
};

export default SearchAvailability;
