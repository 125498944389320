import { FC } from 'react';
import styles from './OrderStatuses.module.scss';
import { useStore } from 'effector-react';
import { $orderData } from 'models/Order';
import { $statuses } from 'models/Hub';
import { IStatus } from './interfaces';

const OrderStatuses: FC = () => {
  const data = useStore($orderData);
  const statuses = useStore($statuses);

  return (
    <div className={styles.container}>
      <p className='light_subtitle'>История смены статусов:</p>
      <div className={styles.list}>
        <div className={styles.chain} />
        {data.statuses.map((item: IStatus, key: number) => (
          <div className={styles.item} key={key}>
            <div className={styles.ball}
                 style={{ backgroundColor: item.isCompleted ? statuses[item.statusId].color : '#E2EBF4' }} />
            <div className={styles.info}>
              <p className={styles.title} style={{ opacity: item.isCompleted ? 1 : 0.3 }}>{item.title}</p>
              <p className={styles.time}>{item.time}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderStatuses;
