import { sample } from 'effector';
import {
  $chefDates,
  $chefDeliveryReports,
  $chefDeliverySorting,
  $chefPreorders,
  $chefPreordersSorting,
  $operatorDates,
  $operatorPreorders,
  $operatorSorting,
  $restaurant,
  getChefData,
  getChefDeliveryReportsFx,
  getChefPreordersFx,
  getOperatorData,
  getOperatorPreordersFx,
  ReportsGate,
  setChefDeliverySorting,
  setChefPreordersSorting,
  setOperatorSorting,
} from '.';
import { changeSorting, sortTable } from '../../pages/Reports/utils';
import { $allowedRestaurants } from 'models/Auth';

sample({
  clock: $allowedRestaurants,
  filter: (allowedRestaurants) => !!allowedRestaurants,
  fn: (allowedRestaurants) => allowedRestaurants[0],
  target: $restaurant,
});

sample({
  clock: [ReportsGate.open, getChefData],
  source: { dates: $chefDates, restaurantData: $restaurant },
  fn: ({ dates, restaurantData }) => ({ dates, restaurant: restaurantData.id }),
  target: [getChefDeliveryReportsFx, getChefPreordersFx],
});

sample({
  clock: [ReportsGate.open, getOperatorData],
  source: { dates: $operatorDates, restaurantData: $restaurant },
  fn: ({ dates, restaurantData }) => ({ dates, restaurant: restaurantData.id }),
  target: getOperatorPreordersFx,
});

sample({
  clock: setChefDeliverySorting,
  source: { sorting: $chefDeliverySorting },
  fn: ({ sorting }, cellIndex) => changeSorting(sorting, cellIndex),
  target: $chefDeliverySorting,
});

sample({
  clock: setChefPreordersSorting,
  source: { sorting: $chefPreordersSorting },
  fn: ({ sorting }, cellIndex) => changeSorting(sorting, cellIndex),
  target: $chefPreordersSorting,
});

sample({
  clock: setOperatorSorting,
  source: { sorting: $operatorSorting },
  fn: ({ sorting }, cellIndex) => changeSorting(sorting, cellIndex),
  target: $operatorSorting,
});

sample({
  clock: $chefDeliverySorting,
  source: { data: $chefDeliveryReports },
  fn: ({ data }, sorting) => sortTable(data, sorting),
  target: $chefDeliveryReports,
});

sample({
  clock: $chefPreordersSorting,
  source: { data: $chefPreorders },
  fn: ({ data }, sorting) => sortTable(data, sorting),
  target: $chefPreorders,
});

sample({
  clock: $operatorSorting,
  source: { data: $operatorPreorders },
  fn: ({ data }, sorting) => sortTable(data, sorting),
  target: $operatorPreorders,
});

sample({
  clock: getOperatorPreordersFx.doneData,
  source: { data: $operatorPreorders, sorting: $operatorSorting },
  fn: ({ data, sorting }) => sortTable(data, sorting),
  target: $operatorPreorders,
});

sample({
  clock: getChefDeliveryReportsFx.doneData,
  source: { data: $chefDeliveryReports, sorting: $chefDeliverySorting },
  fn: ({ data, sorting }) => sortTable(data, sorting),
  target: $chefDeliveryReports,
});

sample({
  clock: getChefPreordersFx.doneData,
  source: { data: $chefPreorders, sorting: $chefPreordersSorting },
  fn: ({ data, sorting }) => sortTable(data, sorting),
  target: $chefPreorders,
});