import { FC, memo } from 'react';
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg';
import styles from './Location.module.css';
import useLocation from './useLocation';

const Location: FC = () => {
  const { locationPopupRef, onCopyAddress } = useLocation();

  return (
    <div>
      <LocationIcon className={styles.icon} onClick={onCopyAddress} width={25} height={27} />
      <div className={styles.popup} ref={locationPopupRef}>
        <p className={styles.text}>Адрес скопирован</p>
      </div>
    </div>
  );
};

export default Location;
