import { FC } from 'react';
import styles from '../../Reports.module.scss';
import Calendar from 'shared/components/Calendar';
import {
  $chefDates,
  $chefDeliveryReports,
  $chefDeliverySorting,
  $chefPreorders,
  $chefPreordersSorting,
  $restaurant,
  getChefData,
  setChefDates,
  setChefDeliverySorting,
  setChefPreordersSorting,
  setRestaurant,
} from 'models/Reports';
import Selector from 'shared/components/Selector';
import { useStore } from 'effector-react';
import ReportsTable from '../ReportsTable';
import Button from 'shared/ui/Button';
import { $allowedRestaurants } from 'models/Auth';

const ChefReports: FC = () => {
  const chefDeliveryReports = useStore($chefDeliveryReports);
  const chefPreorders = useStore($chefPreorders);
  const allowedRestaurants = useStore($allowedRestaurants);

  return (
    <>
      <div className={styles.table__section}>
        <div className={styles.table__calendars}>
          <Calendar index={0} label='От' onSelect={setChefDates} storeDate={$chefDates} />
          <Calendar index={1} label='До' onSelect={setChefDates} storeDate={$chefDates} />
          {allowedRestaurants.length > 1 && (<Selector
            onSelect={setRestaurant}
            storeData={$allowedRestaurants}
            storeValue={$restaurant}
          />)}
          <Button onClick={getChefData} text='Применить' theme='highlighted' />
        </div>
        <h2 className={styles.table__title}>{chefDeliveryReports.title}</h2>
        <ReportsTable
          changeSorting={setChefDeliverySorting}
          markup='150px 300px 300px 300px'
          storeData={$chefDeliveryReports}
          storeSorting={$chefDeliverySorting}
        />
      </div>
      <div className={styles.table__section}>
        <h2 className={styles.table__title}>{chefPreorders.title}</h2>
        <ReportsTable
          changeSorting={setChefPreordersSorting}
          markup='150px 300px 300px 300px 300px 300px'
          storeData={$chefPreorders}
          storeSorting={$chefPreordersSorting}
        />
      </div>
    </>
  );
};

export default ChefReports;
