import { Component } from 'react';
import ErrorPopup from '../../ui/ErrorPopup';

export default class ErrorBoundary extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPopup error={'Произошла внутренняя ошибка!'} />;
    }
    return this.props.children;
  }
}
