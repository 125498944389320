import { ReportsData, Rows, Sorting, SubRows } from './interfaces';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const exportToXLSX = async (data: ReportsData, fileName: string) => {
  const { columnInfos, rows } = data;
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Таблица 1');
  const arrayData: string[][] = [[]];
  const wColumns: any[] = [];
  columnInfos.forEach(({ title }: { title: string }) => {
    wColumns.push({
      header: title,
      key: 'title',
      width: title.length,
    });
  });

  rows.forEach(({ cells }: Rows, i: number) => {
    arrayData[i] = new Array(cells.length).fill('');
    cells.forEach(({ subRows }: { subRows: SubRows[] }, index: number) =>
      subRows.forEach(({ value }: { value: string }) => {
        arrayData[i][index] += `${value}\n`;
        const currentLength = wColumns[index].width;
        const newLength = value.length;
        wColumns[index].width = currentLength < newLength ? newLength : currentLength;
      })
    );
  });
  worksheet.columns = wColumns;

  worksheet.addRows(arrayData);

  wColumns.forEach(({ cells }: Rows, i: number) => {
    const column = worksheet.getColumn(i + 1);
    column.eachCell((cell, rowNumber: number) => {
      cell.alignment = { wrapText: true };
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
  });
};

export const sortTable = (data: ReportsData, sorting: Sorting) => {
  const reportsData = { ...data };
  const { cellIndex, type: sortingType } = sorting;
  if (cellIndex !== -1) {
    const { type } = data.columnInfos[cellIndex];
    reportsData.rows.sort((a, b) => {
      const aValue = a.cells[cellIndex].subRows[0]?.value;
      const bValue = b.cells[cellIndex].subRows[0]?.value;

      if (type === 'string') {
        if (aValue > bValue) {
          return sortingType === 'Ascending' ? 1 : -1;
        }
        if (aValue < bValue) {
          return sortingType === 'Ascending' ? -1 : 1;
        }
      }

      if (type === 'number') {
        return sortingType === 'Ascending'
          ? Number(aValue) - Number(bValue)
          : Number(bValue) - Number(aValue);
      }

      if (type === 'date') {
        const regex = /(\d{2}).(\d{2}).(\d{4})/;
        const aArray = regex.exec(aValue);
        const bArray = regex.exec(bValue);

        if (aArray && bArray) {
          const aDate = Date.parse(`${aArray[3]}-${aArray[2]}-${aArray[1]}`);
          const bDate = Date.parse(`${bArray[3]}-${bArray[2]}-${bArray[1]}`);

          return sortingType === 'Ascending' ? aDate - bDate : bDate - aDate;
        }
      }

      if (type === 'price') {
        const aPrice = aValue.slice(0, -5).replaceAll(' ', '');
        const bPrice = bValue.slice(0, -5).replaceAll(' ', '');
        return sortingType === 'Ascending'
          ? Number(aPrice) - Number(bPrice)
          : Number(bPrice) - Number(aPrice);
      }

      if (type === 'time') {
        const aTime = Number(aValue.slice(0, 5).replaceAll(':', ''));
        const bTime = Number(bValue.slice(0, 5).replaceAll(':', ''));
        return sortingType === 'Ascending' ? aTime - bTime : bTime - aTime;
      }
      return 0;
    });

    return data;
  }

  return data;
};

export const changeSorting = (sorting: Sorting, cellIndex: number): Sorting => {
  if (sorting.cellIndex === cellIndex) {
    if (sorting.type === 'Descending') {
      return { ...sorting, type: 'Ascending' };
    }
    if (sorting.type === 'Ascending') {
      return { ...sorting, type: 'Descending' };
    }
  }
  return { cellIndex, type: 'Descending' };
};
