import { $order } from 'shared/api/REST/config';
import { setError } from 'models/Hub';

export const sendToAloha = async (orderId: number) => {
  try {
    const { data } = await $order.post('/SendToAloha', { orderId });
    const { success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return data;
  } catch (err) {
    return [];
  }
};

export const sendToWhiteCabs = async (orderId: number) => {
  try {
    const { data } = await $order.post('/SendToWhiteCabs', { orderId });
    const { success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return success;
  } catch (err) {
    return [];
  }
};