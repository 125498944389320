const ApplyFilter = (arr: any, filter: any) => {
  const index = arr.findIndex((item: any) => item === filter);
  if (index === -1) {
    const data = [...arr];
    data.push(filter);
    return data;
  } else {
    const data = [...arr];
    data.splice(index, 1);
    return data;
  }
};

export default ApplyFilter;
