import { FC } from 'react';
import styles from './OrderHeader.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderHeaderProps } from './interfaces';
import OrderPopup from './components/OrderPopup';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import Location from './components/Location';

const OrderHeader: FC<OrderHeaderProps> = ({ title }) => {
  const location = useLocation();
  const section = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const close = () => {
    navigate(`/hub/${section}`, { replace: true });
  };

  return (
    <header className={styles.container}>
      <h1>{title}</h1>
      <div className={styles.icons}>
        <Location />
        <OrderPopup />
        <CloseIcon className={styles.icon} onClick={close} />
      </div>
    </header>
  );
};

export default OrderHeader;
