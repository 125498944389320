import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import OrdersStats from './components/OrdersStats';
import styles from './styles/Orders.module.scss';
import Selector from 'shared/components/Selector';
import { useGate, useStore } from 'effector-react';
import {
  $query,
  $restaurant,
  nextPage,
  prevPage,
  OrdersGate,
  setQuery,
  setRestaurant,
  $pageNum,
  $orders, $ordersSorting, setOrdersSorting,
} from 'models/Orders';
import Table from 'components/Table';
import { OrdersTableTitles } from 'shared/constants/TablesTitles';
import OrdersRows from './components/OrdersRows';
import { useLocation } from 'react-router-dom';
import { OrdersTableStyles } from 'components/Table/TablesStyles';
import HubTitle from 'shared/components/SectionTitle';
import Input from 'shared/ui/Input';
import Button from 'shared/ui/Button';
import Paginator from 'components/Table/components/Paginator';
import { $allowedFeatures, $allowedRestaurants, $role } from 'models/Auth';
import { flushInput } from './utils/Orders';
import { $pagesAmount } from 'models/Orders';
import SearchIcons from 'shared/ui/Input/components/SearchIcons';

const Orders: FC = () => {
  useGate(OrdersGate);
  const location = useLocation();
  const dataType = location.pathname.includes('/order/') ? 'narrowed' : 'normal';
  const allowedFeatures = useStore($allowedFeatures);
  const allowedRestaurants = useStore($allowedRestaurants);
  const role = useStore($role);
  const query = useStore($query);
  const pagesAmount = useStore($pagesAmount);

  return (
    <section>
      <div className='section__head'>
        <div className={styles.header}>
          <HubTitle title={allowedRestaurants[0]?.title ?? 'Заказы'} />
          <Input
            icon={<SearchIcons onFlushInput={flushInput} query={query} />}
            placeholder='Поиск заказа...'
            onChange={setQuery}
            value={query}
          />
        </div>
        {/*{allowedRestaurants.length > 1 && (*/}
        {/*  <div className={styles.filter}>*/}
        {/*    <Selector*/}
        {/*      onSelect={setRestaurant}*/}
        {/*      storeData={$allowedRestaurants}*/}
        {/*      storeValue={$restaurant}*/}
        {/*    />*/}
        {/*    /!*<Selector onSelect={setSearchCategory} storeData={$searchCategories} storeValue={$searchCategory} />*!/*/}
        {/*  </div>*/}
        {/*)}*/}
        <OrdersStats />
        {/*<div className={styles.filter}>*/}
        {/*  {allowedFeatures.allowCreateOrderFunc && (*/}
        {/*    <Link to='order/new'>*/}
        {/*      <Button className={styles.button} text='Создать заказ' />*/}
        {/*    </Link>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
      <Table
        changeSorting={setOrdersSorting}
        storeSorting={$ordersSorting}
        markup={OrdersTableStyles.titles[role][dataType]}
        rows={<OrdersRows storeData={$orders} />}
        titles={OrdersTableTitles[role][dataType]}
      />
      {pagesAmount > 1 && (
        <Paginator
          onPrevPage={prevPage}
          onNextPage={nextPage}
          pagesAmountStore={$pagesAmount}
          pageNumStore={$pageNum}
        />
      )}
    </section>
  );
};

export default memo(Orders);
