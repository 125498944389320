import { sample } from 'effector';
import { $hubConnection } from 'models/Hub';
import {
  $isDisableAlohaButton,
  $isDisableStatusButton,
  $isDisableWhiteCabsButton,
  $orderData,
  $orderId,
  enableAlohaButton,
  enableStatusButton,
  enableWhiteCabsButton,
  getOrderByIdFx,
  onSendToAloha,
  onSendToWhiteCabs,
  orderIdGate,
  sendToAlohaFx,
  sendToWhiteCabsFx,
  setForceNextStatus,
  setNextStatus,
} from '.';
import { reset } from 'patronum/reset';
import { changeStatusFx } from 'models/EditOrder';
import { $orders, $ordersSorting } from 'models/Orders';
import { sortTable } from 'models/StopList/utils';

sample({
  clock: [$hubConnection, orderIdGate.open, orderIdGate.state],
  source: { hubConnection: $hubConnection, orderId: orderIdGate.state },
  filter: ({ hubConnection }) => !!hubConnection,
  target: getOrderByIdFx,
});

sample({
  clock: [orderIdGate.open, orderIdGate.state],
  source: orderIdGate.state,
  target: $orderId,
});

sample({
  clock: $ordersSorting,
  source: { data: $orders, sorting: $ordersSorting },
  fn: sortTable,
  target: $orders,
});

sample({
  clock: onSendToAloha,
  source: $orderId,
  fn: (orderId: number) => {
    setTimeout(enableAlohaButton, 5000);
    return orderId;
  },
  target: [$isDisableAlohaButton, sendToAlohaFx],
});

sample({
  clock: onSendToWhiteCabs,
  source: $orderId,
  target: [$isDisableWhiteCabsButton, sendToWhiteCabsFx],
});

sample({
  clock: sendToWhiteCabsFx.doneData,
  target: enableWhiteCabsButton,
});

sample({
  clock: setForceNextStatus,
  source: { orderId: $orderId, orderData: $orderData },
  fn: ({ orderId, orderData }) => ({
    forcibly: true,
    orderId,
    statusId: orderData.serviceData.fulfillmentStatusButton.id,
  }),
  target: changeStatusFx,
});

sample({
  clock: setNextStatus,
  source: { orderId: $orderId, orderData: $orderData },
  fn: ({ orderId, orderData }) => ({ orderId, statusId: orderData.serviceData.fulfillmentStatusButton.id }),
  target: changeStatusFx,
});

sample({
  clock: setNextStatus,
  source: $orderId,
  fn: (orderId: number) => {
    setTimeout(enableStatusButton, 5000);
    return orderId;
  },
  target: $isDisableStatusButton,
});

reset({
  clock: [orderIdGate.close],
  target: [$orderId, $orderData],
});

//$orderData.watch(console.log);
//$nextStatus.watch(console.log);
