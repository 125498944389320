import React from 'react';
import styles from './PriceInfo.module.scss';

const PriceInfo = ({ title, value }) => {
  return (
    <div className={styles.container}>
      <p className='light_subtitle'>{title}:&nbsp;</p>
      <p>{value}</p>
    </div>
  );
};

export default PriceInfo;