import { FC } from 'react';
import styles from '../styles/OrdersStats.module.scss';
import { $totalCounter } from 'models/Orders';
import { useStore } from 'effector-react';
import StatItems from './StatItems';

const OrdersStats: FC = () => {
  const totalCounter = useStore($totalCounter);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {/*<StatItems />*/}
      </div>
      <p className={styles.total}>Выполнено за день: {totalCounter}</p>
    </div>
  );
};

export default OrdersStats;
