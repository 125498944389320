import { FC } from 'react';
import styles from './Loader.module.scss';

const Loader: FC = () => {
  return (
    <div className={styles.container}>
      <svg className={styles.icon} viewBox='25 25 50 50'>
        <circle
          className={styles.icon_front}
          cx='50'
          cy='50'
          r='20'
          fill='none'
          strokeWidth='5'
          strokeMiterlimit='10'
        ></circle>
        <circle
          className={styles.icon_back}
          cx='50'
          cy='50'
          r='20'
          fill='none'
          strokeWidth='5'
          strokeMiterlimit='10'
        ></circle>
      </svg>
    </div>
  );
};

export default Loader;
