import {
  getDashboardPayload,
  getOrdersDataPayload,
  sendRestaurantPayload,
  sendSortingPayload,
} from '../interfaces/ISignalAPI';

export const getDashboardData = async (data: getDashboardPayload): Promise<void> => {
  const { hubConnection } = data;
  await hubConnection.invoke('GetDashboard');
};

export const getOrdersData = async (data: getOrdersDataPayload): Promise<void> => {
  const { hubConnection, pageNum } = data;
  await hubConnection.invoke('GetOrdersForPage', pageNum, 50);
};

export const getQueriedOrders = async (data) => {
  const { hubConnection, query } = data;
  await hubConnection.invoke('SetFinderFilterOrder', query, 0);
};

export const sendSorting = async (data: sendSortingPayload): Promise<void> => {
  const { hubConnection, sorting } = data;
  await hubConnection.invoke('SetOrdersSort', sorting.id);
};

export const sendRestaurant = async (data: sendRestaurantPayload): Promise<void> => {
  const { hubConnection, restaurant } = data;
  await hubConnection.invoke('AddOrdersTagFilter', 'DepartmentId', restaurant.id);
};
