import { FC, useState } from 'react';
import { IOrderList } from 'interfaces/IOrder';
import styles from './EditDishes.module.scss';
import Icons from 'assets/Icons';
import AddDish from '../../AddDish';
import Radio from 'shared/ui/Radio/Radio';
import Checkbox from 'shared/ui/Checkbox/Checkbox';
import useDishes from './useDishes';
import { IEditDishes, IMethods } from 'interfaces/IOrderEdit';
import EditContainer from '../EditContainer';

const EditDishes: FC = () => {
  const [openedId, setOpenedId] = useState<string[]>([]);
  const [addDish, setAddDish] = useState<boolean>(false);
  const [orders, setOrders] = useState<IOrderList[]>([]);

  const remove = (key: number) => {
    const data = [...orders];
    data.splice(key, 1);
    setOrders(data);
  };

  const handleItem = (id: string) => {
    const data = [...openedId];
    if (data.includes(id)) {
      const index = data.indexOf(id);
      index !== -1 && data.splice(index, 1);
    } else {
      data.push(id);
    }
    setOpenedId(data);
  };

  const modifyOneAmount = (amount: number, key: number) => {
    const data = [...orders];
    const amountResult = data[key].amount + amount;
    if (amountResult > 0) {
      data[key].amount = amountResult;
      setOrders(data);
    }
  };

  const modifyAmount = (amount: string, key: number) => {
    const data = [...orders];

    if (amount === '-') {
      return;
    } else if (amount === '0') {
      return;
    } else if (amount === '') {
      data[key].amount = 1;
    } else {
      data[key].amount = Number(amount);
    }
    setOrders(data);
  };

  const modifyMethod = (key: number, i: number) => {
    /*const data = [...orders];
    const methods = data[key].methods;
    methods.forEach((item) => (item.active = false));
    methods[i].active = true;
    setOrders(data);*/
  };

  const modifyAdditives = (key: number, i: number) => {
    /*const data = [...orders];
    const additives = data[key].additives;
    const active = additives[i].active;
    additives[i].active = !active;
    setOrders(data);*/
  };

  const saveData = () => {
  };

  const dishes = useDishes(155);

  return (
    <EditContainer saveData={saveData} title='Редактор блюд'>
      <>
        {/*<ButtonEdit onClick={() => setAddDish(true)} text='Добавить блюдо' />*/}
        <form className={styles.dishes_items}>
          {dishes.map((item: IEditDishes, key) => (
            <div className={styles.dishes_item} key={item.id}>
              <div
                className={styles.dishes_item_container}
                onClick={() => handleItem(item.id.toString())}
              >
                <p>{item.title}</p>
                {openedId.includes(item.id.toString()) ? (
                  <Icons name='UpArrow' />
                ) : (
                  <Icons name='DownArrow' />
                )}
              </div>
              {openedId.includes(item.id.toString()) && (
                <div className={styles.dishes_details}>
                  <span className={styles.dishes_details_item}>
                    <p className='light_subtitle'>Кол-во:</p>
                    <div className={styles.dishes_counter}>
                      <span
                        className={styles.dishes_counter_button}
                        onClick={() => modifyOneAmount(-1, key)}
                      >
                        <Icons name='Minus' />
                      </span>
                      <input
                        className={styles.dishes_counter_value}
                        onChange={(e) => modifyAmount(e.target.value, key)}
                        type='number'
                        min='1'
                        max='10000'
                        value={item.amount}
                      ></input>
                      <span
                        className={styles.dishes_counter_button}
                        onClick={() => modifyOneAmount(1, key)}
                      >
                        <Icons name='Plus' />
                      </span>
                    </div>
                  </span>
                  <span className={styles.dishes_details_item}>
                    <p className='light_subtitle'>Цена:</p>
                    <p>{item.price}</p>
                  </span>
                  {item.comment && (
                    <span className={styles.dishes_details_item}>
                      <p className='light_subtitle'>Комментарий:</p>
                      <p>«{item.comment}»</p>
                    </span>
                  )}
                  {item.methods.length > 0 && (
                    <span className={styles.dishes_details_select}>
                      <p className='light_subtitle'>Способ приготовления:</p>
                      {item.methods.map((item: IMethods, i: number) => (
                        <div
                          className={styles.dishes_details_option}
                          key={item.id}
                          onClick={() => modifyMethod(key, i)}
                        >
                          <Radio active={item.isActive} label={item.title} />
                        </div>
                      ))}
                    </span>
                  )}
                  {item.additives.length > 0 && (
                    <span>
                      <p className='light_subtitle'>Дополнительно:</p>
                      {item.additives.map((item, i) => (
                        <div
                          className={styles.dishes_additionals_item}
                          onClick={() => modifyAdditives(key, i)}
                        >
                          <Checkbox checked={item.isActive} label={item.title} />
                          <p className='light_subtitle'>&nbsp;+{item.price}</p>
                        </div>
                      ))}
                    </span>
                  )}
                  <div className={styles.dishes_details_remove} onClick={() => remove(key)}>
                    Удалить блюдо
                    {/*<Icons name='RightChevron' />*/}
                  </div>
                </div>
              )}
            </div>
          ))}
        </form>
        {addDish && <AddDish close={setAddDish} />}
      </>
    </EditContainer>
  );
};

export default EditDishes;
