export const sortTable = ({ data, sorting }) => {
  const key = Object.keys(data[0])[sorting.cellIndex];
  const sortingType = sorting.type;
  return [...data].sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];
    const type = typeof aValue;

    if (type === 'string') {
      if (aValue > bValue) {
        return sortingType === 'Ascending' ? 1 : -1;
      }
      if (aValue < bValue) {
        return sortingType === 'Ascending' ? -1 : 1;
      }
    }

    if (type === 'number') {
      return sortingType === 'Ascending'
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    return 0;
  });
};

export const StopListSorting = {
  cellIndex: -1,
  type: 'Ascending',
};

