import { setError } from 'models/Hub';
import { $order } from './config';
import { ChangeRestaurantPayload, ChangeStatusPayload } from 'shared/api/interfaces/IOrderEditApi';

export const changeRestaurant = async (payload: ChangeRestaurantPayload) => {
  try {
    const { data } = await $order.post('/changerestaurant', payload);
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};

export const changeStatus = async (payload: ChangeStatusPayload) => {
  try {
    const { data } = await $order.post('/changestatus', payload);
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};
