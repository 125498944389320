const phoneNormalizer = (value: string) => {
  const numberString = value.replace(/\D/g, '');

  if (value === '+') {
    return `+7 (`;
  }

  if (value === '7' || value === '8') {
    return `+7 (${numberString}`;
  }

  if (value.length === 1) {
    return `+7 (${numberString}`;
  }

  if (value.length === 3 || numberString.length === 12) {
    return;
  }

  if (numberString.length > 9) {
    return `+7 (${numberString.substring(1, 4)}) ${numberString.substring(
      4,
      7
    )}-${numberString.substring(7, 9)}-${numberString.substring(9)}`;
  }

  if (numberString.length > 7) {
    return `+7 (${numberString.substring(1, 4)}) ${numberString.substring(
      4,
      7
    )}-${numberString.substring(7)}`;
  }

  if (numberString.length > 4) {
    return `+7 (${numberString.substring(1, 4)}) ${numberString.substring(4)}`;
  }

  if (numberString.length > 1) {
    return `+7 (${numberString.slice(1)}`;
  }

  if (numberString.length === 1) {
    return '+7 (';
  }

  return value;
};

export default phoneNormalizer;
