import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'shared/widgets/Header';
import Loader from 'shared/ui/Loader/Loader';
import { $loader } from 'models/Layout';
import { useStore } from 'effector-react';
import { $error, $isHubDataLoading } from 'models/Hub';
import { $isAuthDataLoading, $userData } from 'models/Auth';
import ErrorPopup from 'shared/ui/ErrorPopup';

const Hub: FC = () => {
  const isHubDataLoading = useStore($isHubDataLoading);
  const isAuthDataLoading = useStore($isAuthDataLoading);
  const userData = useStore($userData);
  const isLoading = useStore($loader);
  const error = useStore($error);

  return (
    <>
      <Header />
      <main className='page_content'>
        {!isHubDataLoading && !isAuthDataLoading && userData.authenticated && <Outlet />}
        {isLoading && <Loader />}
        {error && <ErrorPopup error={error} />}
      </main>
    </>
  );
};

export default Hub;
