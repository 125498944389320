import { sample } from 'effector';
import {
  $dates,
  $pageNum,
  $query,
  $restaurant,
  $searchCategory,
  ArchiveGate,
  getArchiveData,
  getArchiveDataFx,
} from '.';

sample({
  clock: [ArchiveGate.open, getArchiveData, $pageNum],
  source: {
    category: $searchCategory,
    dates: $dates,
    pageNum: $pageNum,
    query: $query,
    restaurant: $restaurant,
  },
  target: getArchiveDataFx,
});