import { IRoute } from 'shared/interfaces/IRouter';
import EditDelivery from 'components/Order/components/OrderEdit/EditDelivery';
import EditDishes from 'components/Order/components/OrderEdit/EditDishes';
import EditRestaurant from 'components/Order/components/OrderEdit/EditRestaurant';
import EditStatus from 'components/Order/components/OrderEdit/EditStatus';

export const OrderEditRoutes: IRoute[] = [
  {
    path: 'restaurant',
    element: <EditRestaurant />,
  },
  {
    path: 'delivery',
    element: <EditDelivery />,
  },
  {
    path: 'dishes',
    element: <EditDishes />,
  },
  {
    path: 'status',
    element: <EditStatus />,
  },
];
