import { setError } from 'models/Hub';
import { StatusData } from '../interfaces/IHubApi';
import { $info } from './config';

export const getRestaurants = async () => {
  try {
    const { data } = await $info.get('/restaurantselect');
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};

export const getSearchCategories = async (): Promise<StatusData[]> => {
  try {
    const { data } = await $info.get('/OrderFinderTypes');
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};

export const getStatuses = async (): Promise<StatusData[]> => {
  try {
    const { data } = await $info.get('/FulfillmentStatuses');
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};

export const getSortings = async () => {
  try {
    const { data } = await $info.get('/OrderSortOptions');
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};

export const getSources = async () => {
  try {
    const { data } = await $info.get('/sources');
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return [];
  }
};
