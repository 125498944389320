import { useEffect, useRef } from 'react';

const useSelector = (data) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (listRef.current && !filterRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'hidden';
      }

      if (data.length > 1 && filterRef.current && filterRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'visible';
      }

      if (listRef.current && listRef.current.contains(e.target as Node)) {
        listRef.current.style.visibility = 'hidden';
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [data]);
  return { filterRef, listRef };
};

export default useSelector;
