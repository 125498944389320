import { FC, Dispatch, SetStateAction } from 'react';
import styles from './Categories.module.scss';
import CategoriesList from '../CategoriesList';
import { useStore } from 'effector-react';
import { $restaurant } from 'models/StopList';
import Overlay from './components/Overlay';

interface CategoryProps {
  categories: number[];
  setCategories: Dispatch<SetStateAction<number[]>>;
}

const Categories: FC<CategoryProps> = ({ categories, setCategories }) => {
  const restaurant = useStore($restaurant);

  return (
    <div className={styles.container} style={{ opacity: restaurant ? 0.5 : 1 }}>
      <div className={styles.header}>
        <h2>Категории</h2>
        <div className={styles.counter}>
          <p>Выбрано: {categories.length}</p>
          <span className={styles.reset} onClick={() => setCategories([])}>
            Сбросить
          </span>
        </div>
      </div>
      <CategoriesList setCategories={setCategories} />
      {restaurant && <Overlay />}
    </div>
  );
};

export default Categories;
