import { ChangeEvent, useState } from 'react';
import phoneNormalizer from 'shared/utils/phoneNormalizer';
//import isMobilePhone from "validator/utils/isMobilePhone";
import { getUser } from 'shared/api/OrderEditApi';
import { EditUserProps } from '../interfaces/ICreateOrder';

const useEditUser = (): EditUserProps => {
  const [phone, setPhone] = useState<string>('');
  const [userData, setUserData] = useState<null>(null);

  const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const normalizedPhone = phoneNormalizer(value);
    if (normalizedPhone) {
      return setPhone(normalizedPhone);
    }
  };

  const loadUserData = async (phone: string) => {
    const data = await getUser(phone);
    return setUserData(data);
  };

  const saveData = () => {};

  return {
    loadUserData,
    //userData,
    handlePhone,
    saveData,
    phone,
  };
};

export default useEditUser;
