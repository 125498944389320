import { orderTimeFinish, orderTimeFormat } from '../../utils/Orders';
import styles from './OrderFinishTime.module.scss';
import { isToday } from 'shared/utils/Dates';

const OrderFinishTime = ({ data }) => {
  return (
    <div>
      <p>{data.asap ? 'Как можно скорее' : 'Ко времени'}</p>
      {!!data.promiseTime && (
        <p className={`light_subtitle ${styles.time}`}>Доставить до {orderTimeFinish(data.promiseTime, false)}</p>
      )}
      {!!data.deliveryTime && (
        <p
          className={`light_subtitle ${styles.time}`}
          style={{ color: isToday(data.deliveryTime) ? '#EC6B45' : '#376F62' }}>{orderTimeFinish(data.deliveryTime)} - {orderTimeFinish(data.deliveryTime + 900000, false)}</p>
      )}
    </div>
  );
};

export default OrderFinishTime;
