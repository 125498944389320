const OrderDetailsCell = ({ isWarning, subtitle, title }) => {
  return (
    <div>
      <p>{title}</p>
      <p style={isWarning ? { color: '#EC6B45' } : {}}>{subtitle}</p>
    </div>
  );
};

export default OrderDetailsCell;
