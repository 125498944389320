import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OrderPreview from './components/OrderPreview/';
import CreateOrder from '../CreateOrder';
import { OrderEditRoutes } from '../../routing/OrderEditRoutes';
import styles from './Order.module.scss';

const Order: FC = () => {
  return (
    <section className={styles.container}>
      <Routes>
        <Route path='/' element={<Navigate to='new' />} />
        <Route path='new' element={<CreateOrder />} />
        <Route path=':orderId' element={<OrderPreview />}>
          {OrderEditRoutes.map((route, key) => (
            <Route key={key} {...route} />
          ))}
        </Route>
      </Routes>
    </section>
  );
};

export default Order;
