import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { CountersTabs } from 'pages/Orders/interfaces/IOrders';
import {
  getDashboardData,
  getOrdersData,
  getQueriedOrders,
  sendRestaurant,
  sendSorting,
} from 'shared/api/Signal/Orders';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import {
  getDashboardPayload,
  getOrdersDataPayload,
  getQueriedOrdersPayload,
  sendRestaurantPayload,
  sendSortingPayload,
} from 'shared/api/interfaces/ISignalAPI';
import { debounce } from 'patronum/debounce';
import { StopListSorting } from 'models/StopList/utils';

export const OrdersGate = createGate();
// export const prevPage = createEvent();
// export const nextPage = createEvent();
// export const setCounters = createEvent<CountersTabs[]>();
// export const setHasNextPage = createEvent<boolean>();
export const setCategories = createEvent<any>();
export const setRestaurant = createEvent<SelectorItem>();
export const setAllSorting = createEvent<any>();
export const setStoppedSorting = createEvent<any>();
// export const setOrders = createEvent<any[]>();
// export const setPageNum = createEvent<number>();
// export const setSearchCategory = createEvent<SelectorItem>();
// export const setTotalCounter = createEvent<number>();
export const setQuery = createEvent<string>();
// export const restaurantFx = createEffect<sendRestaurantPayload, void>(sendRestaurant);
// export const sortingFx = createEffect<sendSortingPayload, void>(sendSorting);
// export const getOrdersDataFx = createEffect<getOrdersDataPayload, void>(getOrdersData);
// export const getDashboardFx = createEffect<getDashboardPayload, void>(getDashboardData);
// export const getQueriedOrdersFx = createEffect<getQueriedOrdersPayload, void>(getQueriedOrders);

export const $categories = createStore<any>(Array.from({ length: 1000 }, (_, i) => ({
  id: i,
  name: `Латте Халва ${i}`,
  amount: i + 2,
}))).on(setCategories, (_, data) => data);
// export const $hasNextPage = createStore<boolean>(false).on(
//   setHasNextPage,
//   (_, hasNextPage: boolean) => hasNextPage,
// );
// export const $totalCounter = createStore<number | null>(null).on(
//   setTotalCounter,
//   (_, amount: number) => amount,
// );
// export const $filterId = createStore<number | null>(null).on(
//   setFilter,
//   (_, filter: number) => filter,
// );
export const $restaurant = createStore<SelectorItem | null>(null).on(
  setRestaurant,
  (_, restaurant: SelectorItem) => restaurant,
);
// export const $searchCategory = createStore<SelectorItem>({ id: 0, title: 'По умолчанию' }).on(
//   setSearchCategory,
//   (_, category: SelectorItem) => category,
// );
export const $allDishes = createStore<any>(Array.from({ length: 1000 }, (_, i) => ({
  id: i,
  name: `Латте Халва ${i}`,
})));
export const $allSorting = createStore<any>(StopListSorting).on(
  setAllSorting, (_, sorting) => sorting);
export const $stoppedDishes = createStore<any>(Array.from({ length: 1000 }, (_, i) => ({
  id: i,
  name: `Латте Халва ${i}`,
  addDate: 1665612961 - i,
  type: 'Алоха',
})));
export const $stoppedSorting = createStore<any>(StopListSorting).on(
  setStoppedSorting, (_, sorting) => sorting);
// export const $orders = createStore<any[]>([]).on(setOrders, (_, orders: any[]) => orders);
// export const $pageNum = createStore<number>(1)
//   .on(prevPage, (pageNum: number) => (pageNum > 1 ? pageNum - 1 : pageNum))
//   .on(nextPage, (pageNum: number) => pageNum + 1);
// export const $pagesAmount = createStore<number>(1);
// export const $orderStatuses = createStore(null);
// export const $orderSources = createStore(null);
export const $query = createStore<string>('').on(setQuery, (_, query: string) => query);

export const setDebouncedQuery = debounce({
  source: $query,
  timeout: 350,
});

export const $debouncedQuery = createStore<string>('').on(setDebouncedQuery, (_, debouncedQuery: string) => debouncedQuery);
