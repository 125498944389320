import { useGate, useStore } from 'effector-react';
import { $userData } from 'models/Auth';
import Loader from 'shared/ui/Loader';
import { HubGate } from 'models/Hub';
import { Outlet } from 'react-router-dom';
import useAuth from './useAuth';

const Auth = () => {
  useGate(HubGate);
  useAuth();
  const userData = useStore($userData);

  if (userData.authenticated === null) {
    return <Loader />;
  }

  return <Outlet />;

};

export default Auth;
