import React from 'react';
import styles from './PostCards.module.scss';
import { useStore } from 'effector-react';
import { $orderData } from 'models/Order';

const PostCards = () => {
  const { postcards } = useStore($orderData);

  return (
    <div className={styles.container}>
      {postcards.map((item, key) => (
        <div key={key}>
          <p className='light_subtitle'>Надпись на открытке:</p>
          <p style={{ fontWeight: 700 }}>{item?.message}</p>
        </div>
      ))}
    </div>
  );
};

export default PostCards;