import React from 'react';
import styles from './Overlay.module.scss';

const Overlay = () => {
  return (
    <div className={styles.container}></div>
  );
};

export default Overlay;
