import { Reports } from 'shared/api/interfaces/IReportsAPI';
import { getChefDeliveryReports, getChefPreorders, getOperatorPreorders } from 'shared/api/REST/ReportsApi';
import { DateSelect } from 'shared/components/Calendar/ICalendar';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { defaultDates, ReportsSorting, ReportsTable } from './Placeholders';
import { Sorting } from 'pages/Reports/interfaces';
import { handleDate } from 'shared/utils/Dates';

export const ReportsGate = createGate();

export const getChefData = createEvent<void>();
export const getOperatorData = createEvent<void>();
export const setChefDates = createEvent<DateSelect>();
export const setOperatorDates = createEvent<DateSelect>();
export const setChefDeliverySorting = createEvent<number>();
export const setChefPreordersSorting = createEvent<number>();
export const setOperatorSorting = createEvent<number>();
export const setRestaurant = createEvent<SelectorItem>();

export const getOperatorPreordersFx = createEffect(getOperatorPreorders);
export const getChefDeliveryReportsFx = createEffect(getChefDeliveryReports);
export const getChefPreordersFx = createEffect(getChefPreorders);

export const $chefDeliveryReports = createStore<Reports>(ReportsTable).on(
  getChefDeliveryReportsFx.doneData,
  (_, chefDeliveryReports) => chefDeliveryReports
);
export const $chefPreorders = createStore<Reports>(ReportsTable).on(
  getChefPreordersFx.doneData,
  (_, chefPreorders) => chefPreorders
);
export const $chefDeliverySorting = createStore<Sorting>(ReportsSorting);
export const $chefPreordersSorting = createStore<Sorting>(ReportsSorting);
export const $operatorPreorders = createStore<Reports>(ReportsTable).on(
  getOperatorPreordersFx.doneData,
  (_, operatorPreorders) => operatorPreorders
);
export const $operatorSorting = createStore<Sorting>(ReportsSorting);
export const $restaurant = createStore<SelectorItem>({ id: 1, title: 'Все рестораны' })
  .on(setRestaurant, (_, restaurant) => restaurant)

const [startDate, endDate] = defaultDates();
export const $operatorDates = createStore<Date[]>([startDate, endDate]).on(
  setOperatorDates,
  handleDate
);
export const $chefDates = createStore<Date[]>([startDate, endDate]).on(setChefDates, handleDate);
