import { FC } from 'react';
import styles from '../styles/EditDelivery.module.scss';
import Radio from 'shared/ui/Radio/Radio';
import EditContainer from '../../EditContainer';
import { EditDeliveryFormProps } from '../interfaces/IEditDelivery';

const EditDeliveryForm: FC<EditDeliveryFormProps> = ({
  changePrice,
  changePaying,
  saveData,
  data,
  price,
  paying,
}) => {
  return (
    <EditContainer saveData={saveData} title='Редактор доставки'>
      <>
        <div className={styles.payment_price}>
          <div className={styles.payment_price_section}>
            <p className='light_subtitle'>Стоимость</p>
            <div className={styles.payment_price_container}>
              <input
                className={styles.payment_price_input}
                onChange={changePrice}
                type='text'
                min='1'
                max='10000'
                value={price}
              ></input>
              <span className={styles.payment_price_currency}>₽</span>
            </div>
          </div>
          <div className={styles.payment_method} onClick={() => changePaying('url')}>
            <Radio active={paying === 'url'} label='Оплата по ссылке' />
          </div>
          <div className={styles.payment_method} onClick={() => changePaying('cash')}>
            <Radio active={paying === 'cash'} label='Перевод / Наличные' />
          </div>
        </div>
      </>
    </EditContainer>
  );
};

export default EditDeliveryForm;
