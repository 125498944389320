import { Link, useLocation } from 'react-router-dom';
import Cell from 'components/Table/components/Cell/Cell';
import CopyUrl from './CopyUrl';
import OrderDetailsCell from './OrderDetailsCell';
import OrderFinishTime from './OrderFinishTime';
import OrderSource from './OrderSource';
import OrderStatus from './OrderStatus';
import OrderTime from './OrderTime';
import styles from 'components/Table/styles/Row.module.scss';
import { OrdersTableStyles } from 'components/Table/TablesStyles';
import OrderAddress from './OrderAddress';
import { useStore } from 'effector-react';
import { $role } from 'models/Auth';
import { $orderSources, $orderStatuses } from 'models/Orders';
import { $orderId } from 'models/Order';

const OrdersRow = ({ data, index }) => {
  const location = useLocation();
  const dataType = location.pathname.includes('/order/') ? 'narrowed' : 'normal';
  const role = useStore($role);
  const sources = useStore($orderSources);
  const orderId = useStore($orderId);
  const isHighlighted = orderId === data.orderId;

  if (data) {
    return (
      <Link to={`order/${data.orderId}`}>
        <div
          className={styles.container}
          key={data.orderId}
          style={{
            backgroundColor: isHighlighted ? '#E2EBF4' : index % 2 !== 0 ? '#FBFCFE' : 'transparent',
            gridTemplateColumns: OrdersTableStyles.row[role][dataType],
          }}
        >
          <Cell data={data.orderNum} />
          <Cell data={data.paidTotal} color={data.isPaid ? '#000' : '#EC6B45'} />
          {dataType === 'normal' && role === 'dispatcher' && (
            <OrderDetailsCell
              isWarning={data.restaurant ? !data.restaurant.inPolygon : true}
              title={data.restaurant ? data.restaurant.title : ''}
              subtitle={
                data.restaurant ? data.restaurant.inPolygon ? 'Нет попадания в полигон' : '' : 'Ошибка получения данных'
              }
            />
          )}
          <OrderTime data={data} />
          <OrderFinishTime data={data} />
          <OrderAddress data={data.deliveryAddress} />
          <OrderSource data={sources[data?.sourceId] ?? { icon: '', title: '' }} />
          <OrderStatus data={data.status} />
          {dataType === 'normal' && <CopyUrl slug={`orders/order/${data.orderId}`} />}
        </div>
      </Link>
    );
  } else {
    return <></>;
  }
};

export default OrdersRow;
