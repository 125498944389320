import { FC } from 'react';
import styles from './Radio.module.scss';
import { RadioProps } from './interfaces';

const Radio: FC<RadioProps> = ({ active, label }) => {
  return (
    <>
      <input
        className={styles.custom_radio}
        checked={active}
        onChange={() => ''}
        name='color'
        type='radio'
        id={label}
        value='green'
      />
      <label htmlFor={label}>{label}</label>
    </>
  );
};

export default Radio;
