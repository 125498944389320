import { HubConnectionBuilder, JsonHubProtocol, HubConnection, LogLevel } from '@microsoft/signalr';
import { setCounters, setHasNextPage, setOrders, setPageNum, setTotalCounter } from 'models/Orders';
import { onOrderSound } from 'pages/Orders/utils/Orders';
import { CountersData } from 'pages/Orders/interfaces/IOrders';
import { setOrderData } from 'models/Order';

export const hubConnection = async (): Promise<HubConnection> => {
  const protocol = new JsonHubProtocol();
  const hubConnection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_URL}/talkinghub`)
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .withHubProtocol(protocol)
    .build();
  await hubConnection.start();

  hubConnection.on('ReceiveCounters', (counters: CountersData) => {
    setCounters(counters.units);
    setTotalCounter(counters.allOrders);
  });

  hubConnection.on(
    'ReceivePageOrders',
    async (orders, pageNum, pageSize, hasNextPage, isNewOrder) => {
      //console.log(orders);
      setOrders(orders);
      setPageNum(pageNum);
      setHasNextPage(hasNextPage);
      if (isNewOrder) await onOrderSound();
    },
  );

  hubConnection.on('ReceiveOrderForSideBar', (order) => {
    console.log(order);
    setOrderData(order);
  });
  return hubConnection;
};

export const closeHubConnection = async (hubConnection: HubConnection): Promise<void> => {
  await hubConnection.stop();
};
