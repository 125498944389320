import { FC, useState } from 'react';
import styles from './EditRestaurant.module.scss';
import EditContainer from '../EditContainer';
import { $restaurants } from 'models/Hub';
import { useList, useStore } from 'effector-react';
import { setRestaurant } from 'models/EditOrder';
import { useNavigate } from 'react-router-dom';
import { $orderData } from 'models/Order';

const EditRestaurant: FC = () => {
  const data = useStore($orderData);
  const [id, setId] = useState<number>(data?.restaurantNum);
  const navigate = useNavigate();

  const saveData = async () => {
    setRestaurant(id);
    return navigate(-1);
    // if (success) {
    //   close();
    // }
  };

  return (
    <EditContainer saveData={saveData} title='Изменить ресторан'>
      <div className={styles.list}>
        {useList($restaurants, {
          keys: [id],
          fn: (item) => (
            <div
              className={styles.item}
              onClick={() => setId(item.id)}
              tabIndex={0}
              style={id === item.id ? { background: '#e2ebf4' } : {}}
            >
              {item.title}
            </div>
          ),
        })}
      </div>
    </EditContainer>
  );
};

export default EditRestaurant;
