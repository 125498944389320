import styles from '../styles/OrderStatus.module.scss';
import { useStore } from 'effector-react';
import { $orderStatuses } from 'models/Orders';

const OrderStatus = ({ data }) => {
  const statuses = useStore($orderStatuses);

  return (
    <span className={styles.container} style={{ backgroundColor: statuses[data.id].color ?? '#C5C8D1' }}>
      {data?.title ?? 'Ошибка'}
    </span>
  );
};

export default OrderStatus;
