import { FC } from 'react';
import styles from './Selector.module.scss';
import Icons from 'assets/Icons';
import { SelectorProps } from './interfaces';
import { useList, useStore } from 'effector-react';
import useSelector from './useSelector';

const Selector: FC<SelectorProps> = ({ onSelect, storeData, storeValue }) => {
  const value = useStore(storeValue);
  const data = useStore(storeData);
  const { filterRef, listRef } = useSelector(data);

  return (
    <div className={styles.filter_section} ref={filterRef}>
      <div className={styles.input}>
        {value?.title || 'Ресторан'}
        {data.length > 1 ? <Icons name='DropDown' /> : <></>}
      </div>
      <div className={styles.list} ref={listRef}>
        {useList(storeData, {
          fn: (item) => (
            <div className={styles.item} onClick={() => onSelect(item)}>
              {item.title}
            </div>
          ),
        })}
      </div>
    </div>
  );
};

export default Selector;
