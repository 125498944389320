import { memo } from 'react';
import useSectionTitle from './useSectionTitle';

const SectionTitle = ({ title }) => {
  useSectionTitle(title);

  return <h1>{title}</h1>;
};

export default memo(SectionTitle);
