import { useRef } from 'react';
import { IconCopy, IconSuccess } from 'shared/ui/icons/Order';
import styles from './CopyUrl.module.scss';
import { copyTextToClipboard } from 'shared/utils/copyText';

const CopyUrl = ({ slug }) => {
  const copyIconRef = useRef(null);
  const successIconRef = useRef(null);

  const onClick = async (e) => {
    const url = 'https://coffeemania.io/hub/' + slug;
    await copyTextToClipboard(url, e);
    copyIconRef.current.style.display = 'none';
    successIconRef.current.style.display = 'block';
    setTimeout(() => {
      if (copyIconRef.current && successIconRef.current) {
        copyIconRef.current.style.display = 'block';
        successIconRef.current.style.display = 'none';
      }
    }, 2000);
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <IconCopy ref={copyIconRef} />
      <IconSuccess ref={successIconRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CopyUrl;
