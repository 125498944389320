import { paramsDateDTO } from 'shared/utils/Dates';
import { $report } from './config';
import { ReportsPayload } from '../interfaces/IReportsAPI';
import { setError } from 'models/Hub';

export const getOperatorPreorders = async (params: ReportsPayload) => {
  try {
    const { dates, restaurant } = params;
    const startDate = paramsDateDTO(dates[0]);
    const endDate = paramsDateDTO(dates[1]);

    const { data } = await $report.get(
      `/SpecialOrderListForOper?departmentNo=${restaurant}&startDate=${startDate}&endDate=${endDate}`
    );
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return {
      columnInfos: [],
      rows: [],
      saveDisabled: true,
      stats: [],
      title: '',
      untouchable: false,
    };
  }
};

export const getChefDeliveryReports = async (params: ReportsPayload) => {
  try {
    const { dates, restaurant } = params;
    const startDate = paramsDateDTO(dates[0]);
    const endDate = paramsDateDTO(dates[1]);

    const { data } = await $report.get(
      `/SpecialOrdersDeliveryForChef?departmentNo=${restaurant}&startDate=${startDate}&endDate=${endDate}`
    );
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return { result: null, success: false };
  }
};

export const getChefPreorders = async (params: ReportsPayload) => {
  try {
    const { dates, restaurant } = params;
    const startDate = paramsDateDTO(dates[0]);
    const endDate = paramsDateDTO(dates[1]);

    const { data } = await $report.get(
      `/SpecialOrderListForChef?departmentNo=${restaurant}&startDate=${startDate}&endDate=${endDate}`
    );
    const { result, success } = data;

    if (!success) {
      setError(data.message);
      throw new Error();
    }

    return result;
  } catch (err) {
    return { result: null, success: false };
  }
};
