import { FC, Dispatch, SetStateAction } from 'react';
import Checkbox from 'shared/ui/Checkbox/Checkbox';
import ApplyItem from '../hooks/ApplyFilter';
import styles from '../styles/CategoriesList.module.scss';
import { useStore } from 'effector-react';
import { $categories } from 'models/StopList';

interface CategoriesListProps {
  setCategories: Dispatch<SetStateAction<number[]>>;
}


const CategoriesList: FC<CategoriesListProps> = ({ setCategories }) => {
  const categories = useStore($categories);

  return (
    <div className={styles.container}>
      {categories.map(({ id, name, amount }) => (
        <div className={styles.item} key={id} onClick={() => setCategories(ApplyItem(categories, 1))}>
          <div>
            <p>{name}</p>
            <p className='light_subtitle'>Блюда на стопе: {amount}</p>
          </div>
          <Checkbox checked={categories.includes(1)} label='' />
        </div>
      ))}
    </div>
  );
};

export default CategoriesList;
