import { sample } from 'effector';
import { $userData } from 'models/Auth';
import {
  $hubConnection,
  $restaurants, $searchCategories, $sortings, $sources, $statuses,
  closeHubConnectionFx,
  getRestaurantsFx,
  getSearchCategoriesFx,
  getSortingsFx,
  getSourcesFx,
  getStatusesFx,
  hubConnectionFx,
  HubGate,
} from '.';
import { reset } from 'patronum/reset';

sample({
  clock: [HubGate.open, $userData],
  source: $userData,
  filter: (userData) => userData.authenticated,
  target: [getRestaurantsFx, getSearchCategoriesFx, getSortingsFx, getSourcesFx, getStatusesFx, hubConnectionFx],
});

sample({
  clock: HubGate.close,
  source: { hubConnection: $hubConnection, userData: $userData },
  filter: ({ userData }) => userData.authenticated,
  fn: (_, { userData }) => userData,
  target: closeHubConnectionFx,
});

reset({
  clock: [HubGate.close],
  target: [$restaurants, $searchCategories, $sortings, $sources, $statuses],
});

//$userData.watch(console.log)