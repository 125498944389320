import { FC, memo, useEffect, useState } from 'react';
import styles from './Calendar.module.scss';
import ru from 'date-fns/locale/ru';
import { CalendarProps } from './ICalendar';
import { useStore } from 'effector-react';
import { DayPicker, ClassNames } from 'react-day-picker';
import Caption from './components/Caption';
import Day from './components/Day';
import Days from './components/Days';
import calendarStyles from 'react-day-picker/dist/style.module.css';
import dayStyles from './components/Day/Day.module.scss';
import { ReactComponent as LeftArrow } from 'assets/icons/LeftArrow.svg';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import { format, isValid, parse } from 'date-fns';
import InputMask from 'react-input-mask';
import useCalendar from './useCalendar';

const Calendar: FC<CalendarProps> = ({ index, label, onSelect, storeDate }) => {
  const { inputRef, isVisible, calendarRef, setVisibility } = useCalendar();
  const datesArr = useStore(storeDate);
  const date = datesArr[index];
  const [inputValue, setInputValue] = useState(format(date, 'dd.MM.yyyy'));
  const [month, setMonth] = useState(date);

  const DecreaseDate = () => {
    const initDate = date || new Date();
    initDate.setDate(initDate.getDate() - 1);
    onSelect({ date: initDate, index });
    const inputValue = format(initDate, 'dd.MM.yyyy');
    setInputValue(inputValue);
  };

  const IncreaseDate = () => {
    const initDate = date || new Date();
    initDate.setDate(initDate.getDate() + 1);
    onSelect({ date: initDate, index });
    const inputValue = format(initDate, 'dd.MM.yyyy');
    setInputValue(inputValue);
  };


  const handleDate = (date) => {
    onSelect({ date, index });
    const inputValue = format(date, 'dd.MM.yyyy');
    setInputValue(inputValue);
    setVisibility(false);
  };

  const handleMonthChange = (date) => {
    setMonth(date);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const date = parse(value, 'dd.MM.yyyy', new Date());
    //console.log(date);
    if (isValid(date)) {
      onSelect({ date, index });
    }
  };

  useEffect(() => {
    setMonth(date);
  }, [date]);

  const classNames: ClassNames = {
    ...calendarStyles,
    cell: dayStyles.container,
    day: dayStyles.content,
    day_outside: dayStyles.outside,
    day_selected: dayStyles.selected,
    day_today: dayStyles.today,
    months: styles.months,
    table: styles.table,
    tbody: styles.days,
  };

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.input_container} ref={inputRef}>
        <LeftArrow fill='#2697FF' onClick={DecreaseDate} width={32} />
        <InputMask
          className={styles.input}
          onClick={() => setVisibility(true)}
          mask='99.99.9999'
          onChange={handleInputChange}
          value={inputValue}
        />
        <RightArrow fill='#2697FF' onClick={IncreaseDate} width={32} />
      </div>
      {isVisible && <div className={styles.calendar_container} ref={calendarRef}>
        <DayPicker
          classNames={classNames}
          components={{ Caption, Head: Days, Day }}
          mode='single'
          locale={ru}
          onMonthChange={handleMonthChange}
          month={month}
          onSelect={handleDate}
          selected={date}
        />
      </div>}
    </div>
  );
};

export default memo(Calendar);
