import { TableStyles } from './interfaces/ITable';

export const OrdersTableStyles: TableStyles = {
  row: {
    dispatcher: {
      narrowed: '100px 100px 200px 180px 370px 60px 200px',
      normal: '100px 100px 200px 195px 200px 450px 100px 200px 60px',
    },
    operator: {
      narrowed: '100px 100px 200px 180px 370px 60px 200px',
      normal: '100px 150px 250px 200px 490px 100px 200px 98px',
    },
  },
  titles: {
    dispatcher: {
      narrowed: '100px 100px 200px 180px 373px 150px 128px',
      normal: '100px 100px 200px 195px 200px 468px 172px 128px 30px',
    },
    operator: {
      narrowed: '100px 100px 200px 180px 373px 150px 128px',
      normal: '100px 150px 250px 200px 490px 190px 150px 98px',
    },
  },
};

export const OrderTableStyles = {
  row: '343px 72px 90px',
  titles: '340px 91px 81px',
};

export const ArchiveTableStyles: TableStyles = {
  row: {
    dispatcher: {
      narrowed: '100px 150px 300px 200px 400px 50px',
      normal: '130px 150px 370px 349px 460px 100px 50px',
    },
    operator: {
      narrowed: '150px 150px 300px 500px 98px',
      normal: '200px 200px 300px 750px 83px 98px',
    },
  },
  titles: {
    dispatcher: {
      narrowed: '100px 150px 300px 200px 415px 50px',
      normal: '130px 150px 370px 349px 475px 100px 50px',
    },
    operator: {
      narrowed: '150px 150px 300px 540px 98px',
      normal: '200px 200px 300px 750px 120px 98px',
    },
  },
};

export const StopListTableStyles = {
  stopped: '100px 350px 300px 246px 176px 78px',
  all: '85px 1071px 79px',
};

export const ReportsTableStyles = {
  operator: '150px 200px 200px 200px 200px 200px 200px 200px',
  chefDelivery: '150px 300px 300px 300px',
  chefPreorders: '150px 300px 300px 300px 300px 300px',
};
