import globalStyles from 'components/Table/styles/Row.module.scss';
import { StopListTableStyles } from 'components/Table/TablesStyles';
import Cell from 'components/Table/components/Cell';
import styles from './AllRows.module.scss';
import DishCell from '../DishCell';
import { useStore } from 'effector-react';
import { $allDishes } from 'models/StopList';
import Switcher from 'shared/ui/Switcher';
import { useState } from 'react';

const AllRows = () => {
  const data = useStore($allDishes);
  const [isActive, setActive] = useState(false);

  return (
    <div>
      {data.map(({ id, name }) => (
        <div className={globalStyles.container} style={{ gridTemplateColumns: StopListTableStyles.all }}>
          <Cell className={styles.cell} data={id} />
          <DishCell data={name} />
          <Switcher isActive={isActive} onChange={setActive} />
        </div>
      ))}
    </div>
  );
};

export default AllRows;
