import { FC, memo } from 'react';
import styles from './Input.module.scss';
import { InputProps } from './interfaces';

const Input: FC<InputProps> = ({
                                 className = '',
                                 icon = <></>,
                                 onChange,
                                 onClick = () => undefined,
                                 placeholder,
                                 theme = 'default',
                                 type = 'text',
                                 value,
                               }) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`${styles.container} ${className}`} onClick={onClick}>
      <input
        className={`${styles.input}${theme ? ` ${styles[theme]}` : ''}`}
        onChange={handleOnChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
      {icon}
    </div>
  );
};

export default memo(Input);
