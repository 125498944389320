import styles from './Day.module.scss';
import { useActiveModifiers, useDayRender } from 'react-day-picker';
import { useRef } from 'react';
import { useDayEventHandlers } from 'react-day-picker/dist/hooks/useDayEventHandlers';

const Day = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { divProps, buttonProps } = useDayRender(props.date, props.displayMonth, buttonRef);
  const { onClick }: any = buttonProps;

  return (
    <div onClick={onClick} {...divProps}>
      {props.date.getDate()}
    </div>
  );
};

export default Day;