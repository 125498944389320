import { FC, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './OrderPreview.module.scss';
import Divider from '../Divider';
import { useParams } from 'react-router-dom';
import Loader from 'shared/ui/Loader';
import {
  $orderData,
  $orderSorting,
  orderIdGate,
  sendToAlohaFx,
  setForceNextStatus,
  setOrderSorting,
} from 'models/Order';
import { useGate, useStore } from 'effector-react';
import Table from 'components/Table';
import { OrderTableTitles } from 'shared/constants/TablesTitles';
import { OrderTableStyles } from 'components/Table/TablesStyles';
import OrderRows from '../OrderRows';
import { orderTimeFinish, orderTimeFormat } from 'pages/Orders/utils/Orders';
import Button from 'shared/ui/Button';
import OrderFooter from './components/OrderFooter';
import OrderHeader from '../OrderHeader';
import OrderInfo from './components/OrderInfo';
import OrderStatuses from './components/OrderStatuses';
import PostCards from './components/PostCards';
import PriceInfo from './components/PriceInfo';
import { setQuery } from 'models/Orders';
import OrderAdditives from 'components/Order/components/OrderAdditives';

const OrderPreview: FC = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const section = location.pathname.split('/')[2];
  useGate(orderIdGate, Number(orderId));
  const data = useStore($orderData);
  const isAlohaPending = useStore(sendToAlohaFx.pending);

  if (data) {
    const { orderData, orderList, price, serving, userData } = data;
    const createTime = orderTimeFormat(orderData?.orderTime);
    const deliveryTime = `${orderTimeFinish(orderData.deliveryTime)} - ${orderTimeFinish(orderData.deliveryTime + 900000, false)}`;
    const promiseTime = orderTimeFinish(orderData?.promiseTime);

    const searchPhone = () => {
      setQuery(userData.phone);
    };

    return (
      <>
        <OrderHeader title={`Заказ #${orderData.orderNum}`} />
        <div className={styles.content}>
          <div className={styles.section}>
            {orderData.vendorOrderNum && <OrderInfo title='Внешний номер' value={orderData.vendorOrderNum} />}
            <OrderInfo title='Сумма заказа' value={price.total} />
            <OrderInfo title='Время заказа' value={createTime} />
            {!!orderData.deliveryTime && (
              <OrderInfo title='Доставить до' value={deliveryTime} />
            )}
            {!!orderData.promiseTime && (
              <OrderInfo title='Доставить до' value={promiseTime} />
            )}
          </div>
          <Divider />
          <div className={styles.section}>
            <OrderInfo title='Имя' value={userData.name} />
            <OrderInfo title='Телефон' value={userData.phone} />
            <OrderInfo title='Email' value={userData.email} />
            <OrderInfo title='Способ оплаты' value={userData.payment} />
            {userData.ordersAmount > 1 && (
              <OrderInfo
                className={styles.ordersAmount}
                onClick={searchPhone}
                title='Заказов'
                value={userData.ordersAmount}
              />
            )}
          </div>
          {userData.ordersAmount === 1 && (
            <p className={styles.firstOrder}>Первый заказ</p>
          )}
          <Divider />
          {orderData.comment && (
            <div className={styles.section}>
              <OrderInfo className={styles.comment} title='Комментарий гостя' value={orderData.comment} />
            </div>
          )}
          <Table
            changeSorting={setOrderSorting}
            storeSorting={$orderSorting}
            markup={OrderTableStyles.titles}
            rows={<OrderRows data={orderList} />}
            titles={OrderTableTitles}
          />
          {data.serviceData.showForcedStatusChangeButton && (
            <Button className={styles.aloha_button} disabled={isAlohaPending} onClick={setForceNextStatus}
                    text='Продолжить обработку' theme='highlighted' />
          )}
          <PostCards />
          <Divider />
          <div className={styles.section}>
            {serving.map((item, key) => (
              <OrderAdditives key={key} title={item.title} value={item.status} />
            ))}
          </div>
          <Divider />
          <div className={styles.section}>
            <PriceInfo title='Сумма заказа' value={price.sum} />
            {!!price.scores && <PriceInfo title='Баллы' value={price.scores} />}
            {price.totalDiscount && (
              <PriceInfo title='Скидка' value={price.totalDiscount} />
            )}
            {price.delivery && (
              <PriceInfo title='Стоимость доставки' value={price.delivery} />
            )}
            <PriceInfo title='Оплачено (сумма в ALOHA)' value={price.paidTotal} />
          </div>
          <Divider />
          <OrderStatuses />
        </div>
        {section === 'orders' && <OrderFooter />}
        <Outlet />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default memo(OrderPreview);
