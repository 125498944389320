import { FC } from 'react';

type IconsProps = {
  name: string;
};

const Icons: FC<IconsProps> = ({ name }) => {
  if (name === 'Search') {
    return (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.332682 5.33334C0.332682 8.09477 2.57126 10.3333 5.33268 10.3333C6.48711 10.3333 7.55015 9.94211 8.39647 9.285C8.42834 9.35244 8.47217 9.41564 8.52794 9.47141L12.5279 13.4714C12.7883 13.7318 13.2104 13.7318 13.4708 13.4714C13.7311 13.2111 13.7311 12.789 13.4708 12.5286L9.47075 8.52861C9.41498 8.47283 9.35178 8.42901 9.28434 8.39713C9.94145 7.55082 10.3327 6.48777 10.3327 5.33334C10.3327 2.57192 8.09411 0.333344 5.33268 0.333344C2.57126 0.333344 0.332682 2.57192 0.332682 5.33334ZM1.66602 5.33334C1.66602 3.3083 3.30764 1.66668 5.33268 1.66668C7.35773 1.66668 8.99935 3.3083 8.99935 5.33334C8.99935 7.35839 7.35773 9.00001 5.33268 9.00001C3.30764 9.00001 1.66602 7.35839 1.66602 5.33334Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'DropDown') {
    return (
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.94 0.726715L4 3.78005L7.06 0.726715L8 1.66672L4 5.66671L0 1.66672L0.94 0.726715Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'Fullscreen') {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.0012 0.997861C14.0012 0.446758 14.4489 0 15.0012 0L19.0012 1.78431e-07C19.2665 1.78431e-07 19.5209 0.105175 19.7084 0.292379C19.896 0.479583 20.0013 0.733474 20.0012 0.998178L19.9999 5.03248C19.9998 5.58359 19.5519 6.0302 18.9996 6.03003C18.4473 6.02985 17.9998 5.58295 17.9999 5.03185L18.0004 3.41785L13.7092 7.72924C13.3199 8.12009 12.6867 8.12197 12.295 7.73344C11.9033 7.34491 11.9014 6.7131 12.2908 6.32226L16.5971 1.99572H15.0012C14.4489 1.99572 14.0012 1.54896 14.0012 0.997861ZM7.70621 12.2666C8.09789 12.6551 8.09978 13.2869 7.71042 13.6777L3.4041 18.0043H5.00002C5.5523 18.0043 6.00002 18.451 6.00002 19.0021C6.00002 19.5532 5.5523 20 5.00002 20H1C0.734728 20 0.480326 19.8948 0.292781 19.7076C0.105235 19.5204 -8.429e-05 19.2665 5.06162e-08 19.0018L0.00128453 14.9675C0.00145995 14.4164 0.449317 13.9698 1.0016 13.97C1.55389 13.9701 2.00146 14.417 2.00128 14.9682L2.00077 16.5822L6.29201 12.2708C6.68137 11.8799 7.31453 11.878 7.70621 12.2666Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'Close') {
    return (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L9 7.58579L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292894 0.292893C-0.0976295 0.683417 -0.0976295 1.31658 0.292894 1.70711L7.58579 9L0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683418 18.0976 1.31658 18.0976 1.70711 17.7071L9 10.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L10.4142 9L17.7071 1.70711Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'LeftArrow') {
    return (
      <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.86941 7.80047C4.61142 8.06316 4.18932 8.06696 3.92664 7.80897L0.532698 4.47563C0.405067 4.35028 0.33317 4.17889 0.33317 4C0.33317 3.82111 0.405067 3.64972 0.532698 3.52437L3.92664 0.191034C4.18932 -0.0669594 4.61142 -0.0631571 4.86941 0.199528C5.1274 0.462212 5.1236 0.884305 4.86092 1.1423L2.63004 3.33333L12.9998 3.33333C13.368 3.33333 13.6665 3.63181 13.6665 4C13.6665 4.36819 13.368 4.66667 12.9998 4.66667L2.63004 4.66667L4.86092 6.8577C5.1236 7.11569 5.1274 7.53779 4.86941 7.80047Z'
          fill='#2697FF'
        />
      </svg>
    );
  }

  if (name === 'Error') {
    return (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM6.3 10.5001V9.10009H7.7V10.5001H6.3ZM6.3 3.49991V7.69991H7.7V3.49991H6.3Z'
          fill='white'
        />
      </svg>
    );
  }

  if (name === 'Minus') {
    return (
      <svg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 0.428467H8V1.57132H0V0.428467Z' fill='#2697FF' />
      </svg>
    );
  }

  if (name === 'Plus') {
    return (
      <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.57031 3.42857V0H3.42746L3.42746 3.42857H0V4.57143H3.42746L3.42745 8H4.57031V4.57143H8V3.42857H4.57031Z'
          fill='#2697FF'
        />
      </svg>
    );
  }

  if (name === 'DownArrow') {
    return (
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0.94 0.726807L4 3.78014L7.06 0.726807L8 1.66681L4 5.66681L0 1.66681L0.94 0.726807Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'UpArrow') {
    return (
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.06 5.6665L4 2.61317L0.94 5.6665L8.21774e-08 4.7265L4 0.726504L8 4.7265L7.06 5.6665Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'Calendar') {
    return (
      <svg
        width='18'
        height='20'
        viewBox='0 0 18 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5 0C4.44772 0 4 0.447715 4 1V2H3C1.34315 2 0 3.34315 0 5V7V17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V7V5C18 3.34315 16.6569 2 15 2H14V1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1V2H6V1C6 0.447715 5.55228 0 5 0ZM13 4H5H3C2.44772 4 2 4.44772 2 5V6H16V5C16 4.44772 15.5523 4 15 4H13ZM2 17V8L16 8V17C16 17.5523 15.5523 18 15 18H3C2.44772 18 2 17.5523 2 17ZM4 11C4 10.4477 4.44772 10 5 10H7C7.55228 10 8 10.4477 8 11V13C8 13.5523 7.55228 14 7 14H5C4.44772 14 4 13.5523 4 13V11Z'
          fill='#C3C5CE'
        />
      </svg>
    );
  }

  if (name === 'RemoveIcon') {
    return (
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.8047 1.13807C12.0651 0.877722 12.0651 0.455612 11.8047 0.195262C11.5444 -0.0650874 11.1223 -0.0650874 10.8619 0.195262L6 5.05719L1.13807 0.195262C0.877722 -0.0650874 0.455612 -0.0650874 0.195263 0.195262C-0.0650863 0.455612 -0.0650863 0.877722 0.195263 1.13807L5.05719 6L0.195262 10.8619C-0.0650874 11.1223 -0.0650874 11.5444 0.195262 11.8047C0.455612 12.0651 0.877722 12.0651 1.13807 11.8047L6 6.94281L10.8619 11.8047C11.1223 12.0651 11.5444 12.0651 11.8047 11.8047C12.0651 11.5444 12.0651 11.1223 11.8047 10.8619L6.94281 6L11.8047 1.13807Z'
          fill='#2697FF'
        />
      </svg>
    );
  }

  if (name === 'AscendingIcon') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='15px'
        viewBox='0 0 24 24'
        width='15px'
        fill='#999bb4'
      >
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z' />
      </svg>
    );
  }

  if (name === 'DescendingIcon') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='15px'
        viewBox='0 0 24 24'
        width='15px'
        fill='#999bb4'
      >
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z' />
      </svg>
    );
  }
  return <></>;
};

export default Icons;
