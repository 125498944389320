import { FC } from 'react';
import styles from './Login.module.scss';
import { $formData, handleChange, loginGate, onLogin, TelegramLogin } from 'models/Auth';
import { useGate, useStore } from 'effector-react';
import { IconTelegram } from 'shared/ui/icons/Login';
import Button from 'shared/ui/Button';
import { useNavigate } from 'react-router-dom';
import useLogin from './useLogin';

const Login: FC = () => {
  const navigate = useNavigate();
  useGate(loginGate, navigate);
  useLogin();
  const { error } = useStore($formData);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.logo}>
          <p>Delivery Hub</p>
        </div>
        {error && <p className={styles.error}>{error}</p>}
        <input
          autoFocus
          name='login'
          //onKeyDown={onEnter}
          className='input'
          onChange={handleChange}
          placeholder='Логин'
          //value={phone}
        />
        <input
          autoFocus
          name='password'
          //onKeyDown={onEnter}
          className='input'
          onChange={handleChange}
          placeholder='Пароль'
          type='password'
        />
        <Button onClick={onLogin} text='Войти' theme='highlighted' />
        {/*<button className='button__highlighted' onClick={login}>*/}
        {/*  Войти*/}
        {/*</button>*/}
        <button className={styles.telegram_button} onClick={TelegramLogin}>
          <IconTelegram width={20} height={20} />{' '}
          <p className={styles.button_label}>Войти с помощью Telegram</p>
        </button>
        {/*isCodeSent && (
          <>
            <div className={styles.code_container}>
              {codeArray?.map((_: HTMLInputElement | string, i: number) => (
                <input
                  autoFocus={i === 0}
                  className={`${styles.code_item}${
                    error.phone ? ` ${styles.input_error}` : ""
                  }`}
                  key={i}
                  onChange={() => ""}
                  onKeyDown={(e) => handleCode(e, i)}
                  ref={(e: HTMLInputElement) => (codeArray[i] = e)}
                  type="text"
                  value={code[i]}
                />
              ))
      </div>
      <p className="light_subtitle">Повторить отправку</p>
      <p className="light_subtitle">Запросить звонок</p>
    </>*/}

        {/*
  !isCodeSent && (
    <button className="button__highlighted" onClick={showCode}>
      Далее
    </button>
  )
*/}
      </div>
    </div>
  );
};
export default Login;
