import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $userData } from 'models/Auth';

const useAuth = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const userData = useStore($userData);

  useEffect(() => {
    //console.log(location)
    // if (userData.authenticated) {
    //   const path = location?.state?.from?.pathname || '/hub/orders';
    //   return navigate(path, { replace: true });
    // }
    if (userData.authenticated === false) {
      const path = '/login';
      return navigate(path, { replace: true });
    }
  }, [userData]);
};

export default useAuth;