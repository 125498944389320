import { FC } from 'react';
// import { Outlet, useLocation } from 'react-router-dom';
// import styles from './Archive.module.scss';
// import { ArchiveTableStyles } from 'components/Table/TablesStyles';
// import Table from 'components/Table';
// import { ArchiveTableTitles } from 'shared/constants/TablesTitles';
// import Icons from '../Orders/components/Icons';
// import Input from 'shared/ui/Input';
// import OrdersRows from '../Orders/components/OrdersRows';
// import { useGate, useStore } from 'effector-react';
// import {
//   $dates,
//   $orders,
//   $pageNum,
//   $pagesAmount,
//   $query,
//   $restaurant,
//   $searchCategory,
//   ArchiveGate,
//   getArchiveData,
//   nextPage,
//   prevPage,
//   setDate,
//   setQuery,
//   setRestaurant,
//   setSearchCategory,
// } from 'models/Archive';
// import Calendar from 'shared/components/Calendar';
// import Selector from 'shared/components/Selector';
// import { $searchCategories } from 'models/Hub';
// import Button from 'shared/ui/Button';
// import Paginator from 'components/Table/components/Paginator';
// import ArchiveRows from './components/ArchiveRows';
// import { $allowedRestaurants, $role } from 'models/Auth';
// import { ReactComponent as CloseIcon } from 'assets/icons/Remove.svg';
// import { ReactComponent as SearchIcons } from 'assets/icons/Search.svg';
// import { flushInput } from './utils/Archive';

const Archive: FC = () => {
  // useGate(ArchiveGate);
  // const role = useStore($role);
  // const query = useStore($query);
  // const location = useLocation();
  // const dataType = location.pathname.includes('/order/') ? 'narrowed' : 'normal';
  // const allowedRestaurants = useStore($allowedRestaurants);

  return <h1>Раздел находится в разработке.</h1>
  // return (
  //   <>
  //     <div className='section__head'>
  //       <div className='section_header'>
  //         <h1>История заказов</h1>
  //         <Input
  //           icon={query.length > 0 ? <CloseIcon className={styles.search_icon} onClick={flushInput} /> :
  //             <SearchIcons className={styles.search_icon} />}
  //           placeholder='Поиск заказа...'
  //           onChange={setQuery}
  //           value={query}
  //         />
  //       </div>
  //       <div className={styles.filters}>
  //         <Calendar index={0} label='От' onSelect={setDate} storeDate={$dates} />
  //         <Calendar index={1} label='До' onSelect={setDate} storeDate={$dates} />
  //         {allowedRestaurants.length > 1 && (<Selector
  //           onSelect={setRestaurant}
  //           storeData={$allowedRestaurants}
  //           storeValue={$restaurant}
  //         />)}
  //         {/*<Selector onSelect={setSearchCategory} storeData={$searchCategories} storeValue={$searchCategory} />*/}
  //         <Button onClick={getArchiveData} text='Применить' theme='highlighted' />
  //       </div>
  //     </div>
  //     <Table
  //       markup={ArchiveTableStyles.titles[role][dataType]}
  //       rows={<ArchiveRows data={$orders} dataType={dataType} />}
  //       titles={ArchiveTableTitles[role][dataType]}
  //     />
  //     <Paginator
  //       onPrevPage={prevPage}
  //       onNextPage={nextPage}
  //       pagesAmountStore={$pagesAmount}
  //       pageNumStore={$pageNum}
  //     />
  //     <Outlet />
  //   </>
  // );
};

export default Archive;
