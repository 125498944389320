import { sample } from 'effector';
import { $allDishes, $allSorting, $stoppedDishes, $stoppedSorting } from '.';
import { sortTable } from 'models/StopList/utils';

sample({
  clock: $stoppedSorting,
  source: { data: $stoppedDishes, sorting: $stoppedSorting },
  fn: sortTable,
  target: $stoppedDishes,
});

sample({
  clock: $allSorting,
  source: { data: $allDishes, sorting: $allSorting },
  fn: sortTable,
  target: $allDishes,
});

// sample({
//   cloc,
// });
