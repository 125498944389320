import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { CountersTabs } from '../../pages/Orders/interfaces/IOrders';
import { getArchiveOrders } from 'shared/api/REST/ArchiveApi';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import { handleDate } from 'shared/utils/Dates';
import { defaultDates } from 'models/Reports/Placeholders';

export const ArchiveGate = createGate();

export const getArchiveDataFx = createEffect(getArchiveOrders);

export const getArchiveData = createEvent<void>();
export const prevPage = createEvent();
export const nextPage = createEvent();
export const setDate = createEvent<any>();
export const setOrders = createEvent<any[]>();
export const setRestaurant = createEvent<SelectorItem>();
export const setSearchCategory = createEvent<SelectorItem>();
export const setQuery = createEvent<string>();

export const $orders = createStore<any[]>([]).on(
  getArchiveDataFx.doneData,
  (_, data: any) => data.orders,
);
export const $pageNum = createStore<number>(1)
  .on(prevPage, (pageNum: number) => (pageNum > 1 ? pageNum - 1 : pageNum))
  .on(nextPage, (pageNum: number) => pageNum + 1);
export const $pagesAmount = createStore<number>(1).on(
  getArchiveDataFx.doneData,
  (_, data: any) => data.pageCount,
);
export const $query = createStore<string>('').on(setQuery, (_, newOrders: string) => newOrders);
export const $restaurant = createStore<SelectorItem>({ id: 1, title: 'Все рестораны' }).on(
  setRestaurant,
  (_, restaurant) => restaurant,
);

export const $searchCategory = createStore<SelectorItem>({ id: 0, title: 'По умолчанию' }).on(
  setSearchCategory,
  (_, category: SelectorItem) => category,
);

const yesterdayDate1 = new Date();
const yesterdayDate2 = new Date();
yesterdayDate1.setDate(yesterdayDate1.getDate() - 1);
yesterdayDate2.setDate(yesterdayDate2.getDate() - 1);

export const $dates = createStore<Date[]>([yesterdayDate1, yesterdayDate2]).on(setDate, handleDate);