import { INavigationItem } from 'shared/widgets/Header/interfaces';

export const Navigation: INavigationItem[] = [
  {
    slug: '/hub/orders',
    navTitle: 'Заказы',
    title: 'Заказы',
    icon: 'Orders',
  },
  {
    slug: '/hub/archive',
    navTitle: 'История',
    title: 'История заказов',
    icon: 'Archive',
  },
  {
    slug: '/hub/stoplist',
    navTitle: 'Стоп-лист',
    title: 'Стоп-лист',
    icon: 'StopList',
  },
  {
    slug: '/hub/reports',
    navTitle: 'Отчёты',
    title: 'Отчёты',
    icon: 'Reports',
  },
  {
    slug: '/hub/areas',
    navTitle: 'Зоны доставки',
    title: 'Зоны доставки',
    icon: 'StopList',
  },
];
