import React from 'react';
import styles from './ErrorPopup.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import { closePopup } from 'shared/components/ErrorBoundary/utils';

const ErrorPopup = ({ error }) => {
  return (
    <div className={styles.container}>
      <div className={styles.popup}>
        <div className={styles.header}>
          Произошла ошибка!
          <CloseIcon className={styles.icon} onClick={closePopup} />
        </div>
        <div className={styles.text}>{error}</div>
      </div>
    </div>
  );
};

export default ErrorPopup;
