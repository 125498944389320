import { useEffect, useState } from 'react';
import { DishItem } from './IDish';

const useDishes = (query: string) => {
  const [result, setResult] = useState<DishItem[]>([]);

  useEffect(() => {
    const Fetch = async () => {
      try {
        const res = [
          {
            id: 8,
            image: 'https://coffeemania.ru/uploads/p1959/c9d6bafe87e5932.jpg',
            params: '300г / 790 ₽',
            title: 'Гаспачо с мясом краба и авокадо',
          },
          {
            id: 5,
            image: 'https://coffeemania.ru/uploads/p514/034a7657a6d1b14.jpg',
            params: '50г / 250 ₽',
            title: 'Авокадо',
          },
        ];
        setResult(res);
        if (query.length > 3) {
          // const { data } = await axios.get(
          //   `${process.env.REACT_APP_API_URL}/hub/orders?q=${query}`
          // );
        }
      } catch (err) {}
    };
    Fetch();
  }, [query]);

  return result;
};

export default useDishes;
