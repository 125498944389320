import { memo } from 'react';
import styles from './OrderSource.module.scss';

const OrderSource = ({ data }) => {
  if (data.icon) {
    return <img className={styles.container} src={data.icon} alt={data.title} />;
  } else {
    return <div></div>;
  }
};

export default memo(OrderSource);
