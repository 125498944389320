import { HubConnection } from '@microsoft/signalr';
import {
  getRestaurants,
  getSearchCategories,
  getSortings,
  getSources,
  getStatuses,
} from 'shared/api/REST/HubApi';
import { closeHubConnection, hubConnection } from 'shared/api/Signal/Hub';
import { combine, createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import { StatusData } from 'shared/api/interfaces/IHubApi';

export const HubGate = createGate();

export const hubConnectionFx = createEffect<void, HubConnection>(hubConnection);
export const closeHubConnectionFx = createEffect<HubConnection, void>(closeHubConnection);
export const getRestaurantsFx = createEffect<void, any>(getRestaurants);
export const getSearchCategoriesFx = createEffect<void, any>(getSearchCategories);
export const getStatusesFx = createEffect<void, any>(getStatuses);
export const getSortingsFx = createEffect<void, any>(getSortings);
export const getSourcesFx = createEffect<void, any>(getSources);

export const setError = createEvent<string>();

export const $hubConnection = createStore<HubConnection | null>(null)
  .on(hubConnectionFx.doneData, (_, hubInstance) => hubInstance)
  .on(closeHubConnectionFx.done, () => null);
export const $isHubDataLoading = combine(
  [
    getRestaurantsFx.pending,
    getSearchCategoriesFx.pending,
    getStatusesFx.pending,
    getSortingsFx.pending,
    getSourcesFx.pending
  ],
  (arr) => arr.some(Boolean)
);
export const $restaurants = createStore<SelectorItem[]>([]).on(
  getRestaurantsFx.doneData,
  (_, restaurants) => restaurants
);
export const $searchCategories = createStore<StatusData[]>([]).on(
  getSearchCategoriesFx.doneData,
  (_, statuses) => statuses
);
export const $statuses = createStore<StatusData[]>([]).on(
  getStatusesFx.doneData,
  (_, statuses) => statuses
);
export const $sortings = createStore<SelectorItem[]>([]).on(
  getSortingsFx.doneData,
  (_, sortings) => sortings
);
export const $sources = createStore<SelectorItem[]>([]).on(
  getSourcesFx.doneData,
  (_, sources) => sources
);
export const $error = createStore<string | null>(null).on(setError, (_, error) => error);
