import { $orderSources, $orderStatuses } from 'models/Orders';
import { useStore } from 'effector-react';
import { memo } from 'react';
import OrdersRow from './OrdersRow';
import styles from '../styles/Orders.module.scss';

const OrdersRows = ({ storeData }) => {
  const data: any = useStore(storeData);

  return (
    <div className={styles.list}>
      {data.map((data: any, index: number) => (
        <OrdersRow
          data={data}
          index={index}
          key={data.orderId}
        />
      ))}
    </div>
  );
};

export default memo(OrdersRows);
