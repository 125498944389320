import styles from './Switcher.module.scss';

const Switcher = ({ isActive, onChange }) => {

  const handleChange = () => {
    onChange(!isActive);
  };

  return (
    <div className={styles.container} onClick={handleChange}>
      <div className={styles.bg} style={isActive ? { background: '#7CBEAE' } : { background: '#999BB4' }}></div>
      <div className={styles.thumb}
           style={{ background: isActive ? '#7CBEAE' : '#999BB4', left: isActive ? 15 : 0 }}></div>
    </div>
  );
};

export default Switcher;
