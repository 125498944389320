import { FC, useState } from 'react';
import styles from './EditStatus.module.scss';
import EditContainer from '../EditContainer';
import { $statuses } from 'models/Hub';
import { useList, useStore } from 'effector-react';
import { setStatus } from 'models/EditOrder';
import { useNavigate } from 'react-router-dom';
import { $orderData } from 'models/Order';

const EditStatus: FC = () => {
  const data = useStore($orderData);
  const [id, setId] = useState<number>(data?.fulfillmentStatus);
  const navigate = useNavigate();

  const saveData = async () => {
    setStatus(id);
    return navigate(-1);
    // if (success) {
    //   //close();
    // }
  };

  return (
    <EditContainer saveData={saveData} title='Изменить статус'>
      <div className={styles.list}>
        {useList($statuses, {
          keys: [id],
          fn: (item) => (
            <div
              className={styles.item}
              onClick={() => setId(item.id)}
              tabIndex={0}
              style={id === item.id ? { background: '#e2ebf4' } : {}}
            >
              <span className={styles.circle} style={{ backgroundColor: item.color }}></span>
              <p>{item.title}</p>
            </div>
          ),
        })}
      </div>
    </EditContainer>
  );
};

export default EditStatus;
