import Input from 'shared/ui/Input';
import styles from './StopListHeader.module.scss';
import SearchIcons from 'shared/ui/Input/components/SearchIcons';
import { $query, setQuery } from 'models/StopList';
import Selector from 'shared/components/Selector';
import { $restaurant, setRestaurant } from 'models/StopList';
import { $allowedRestaurants } from 'models/Auth';
import { useStore } from 'effector-react';
import { flushInput } from './utils';

const StopListHeader = () => {
  const allowedRestaurants = useStore($allowedRestaurants);
  const query = useStore($query);

  return (
    <div className={styles.container}>
      <Input
        className={styles.input}
        icon={<SearchIcons onFlushInput={flushInput} query={query} />}
        placeholder='Поиск позиции...'
        onChange={setQuery}
        value={query}
      />
      {allowedRestaurants.length > 1 && (
        <Selector
          onSelect={setRestaurant}
          storeData={$allowedRestaurants}
          storeValue={$restaurant}
        />
      )}
    </div>
  );
};

export default StopListHeader;
