import { useEffect, useState } from 'react';
import { orderTimeFormat, orderTimeInProgressView } from '../utils/Orders';
import styles from '../styles/OrderTime.module.scss';

const OrderTime = ({ data }) => {
  const [dateInProgress, setDateInProgress] = useState(orderTimeInProgressView(Number(data.orderTime)));
  const dateView = orderTimeFormat(Number(data.orderTime));

  useEffect(() => {
    const timeUpdate = setInterval(() => {
      setDateInProgress(orderTimeInProgressView(Number(data.orderTime)));
    }, 30000);
    return () => clearInterval(timeUpdate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {dateView}
      {data.asap && (<p className={styles.inProgress} style={dateInProgress.isHighlighted ? { color: '#EC6B45' } : {}}>
        В работе: {dateInProgress.value}
      </p>)}
    </div>
  );
};

export default OrderTime;
