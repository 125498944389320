import { ChangeEvent, MouseEvent } from 'react';
import { createStore, combine, createEvent, createEffect } from 'effector';
import { LoginFormFields, LoginPayload, UserData } from 'pages/Login/interfaces';
import { checkUser, login, loginTelegram } from 'shared/api/REST/UserApi';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import { getSearchCategoriesFx, getSortingsFx, getSourcesFx, getStatusesFx } from 'models/Hub';
import { createGate } from 'effector-react';
import { loginRedirect } from 'pages/Login/utils';

export const loginGate = createGate();

export const setField = createEvent();
export const setError = createEvent<string>();
export const onLogin = createEvent<MouseEvent<HTMLButtonElement> | void>();
export const setAllowRedirect = createEvent<boolean>();
export const TelegramLogin = createEvent<MouseEvent<HTMLButtonElement> | void>();

export const checkUserFx = createEffect<void, UserData>(checkUser);
export const loginFx = createEffect<LoginPayload, UserData>(login);
export const telegramLoginFx = createEffect<string, UserData>(loginTelegram);

export const $allowedFeatures = createStore<any>(null)
  .on(loginFx.doneData, (_, userData) => userData?.userCapabilities.access)
  .on(telegramLoginFx.doneData, (_, userData) => userData?.userCapabilities.access)
  .on(checkUserFx.doneData, (_, userData) => userData?.userCapabilities.access);
export const $allowedRestaurants = createStore<SelectorItem[]>(null)
  .on(loginFx.doneData, (_, userData) => userData.userCapabilities.departmentsMainSelect)
  .on(telegramLoginFx.doneData, (_, userData) => userData.userCapabilities.departmentsMainSelect)
  .on(checkUserFx.doneData, (_, userData) => userData.userCapabilities.departmentsMainSelect);
export const $allowRedirect = createStore<boolean>(false);
export const $error = createStore<string>('').on(setError, (_, value) => value);
export const $loginData = createStore<LoginFormFields>({ login: '', password: '' })
  .on(setField, (prev, { key, value }: any) => ({ ...prev, [key]: value }));
export const $role = createStore<string>('')
  .on(loginFx.doneData, (_, userData) => userData.roleName.toLowerCase())
  .on(telegramLoginFx.doneData, (_, userData) => userData.roleName.toLowerCase())
  .on(checkUserFx.doneData, (_, userData) => userData.roleName.toLowerCase());
export const $userData = createStore<UserData>({
  authenticated: null,
  roleName: '',
  userCapabilities: { access: {}, departmentsMainSelect: [] },
}).on(loginFx.doneData, (_, userData) => userData)
  .on(telegramLoginFx.doneData, (_, userData) => userData)
  .on(checkUserFx.doneData, (_, userData) => userData);

export const handleChange = setField.prepend<ChangeEvent<HTMLInputElement>>((e) => ({
  key: e.target.name,
  value: e.target.value,
}));

export const $formData = combine({
  error: $error,
  loginData: $loginData,
});

export const $isAuthDataLoading = combine(
  [
    checkUserFx.pending,
    getSearchCategoriesFx.pending,
    getStatusesFx.pending,
    getSortingsFx.pending,
    getSourcesFx.pending,
  ],
  (arr) => arr.some(Boolean),
);