import { orderTimeInProgressDTO } from '../interfaces/IOrders';
import { isToday } from 'shared/utils/Dates';
import { get } from 'idb-keyval';
import { dates } from 'shared/constants/Dates';
import { setQuery } from 'models/Orders';

const audio = new Audio('https://imgs.coffeemania.ru/Audio/bm90aWZpY0xvbmc.mp3');

export const onOrderSound = async (): Promise<void> => {
  const isMuted: boolean | undefined = await get('isMuted');
  if (!isMuted) await audio.play();
};

export const orderTimeFormat = (data: number, showDay: boolean = true): string => {
  const date = new Date(data);
  const inDayTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  if (showDay) {
    if (isToday(date)) {
      return `Сегодня в ${inDayTime}`;
    }
    const day = date.getDate();
    const month = dates.caseMonths[date.getMonth()];
    return `${day} ${month} в ${inDayTime}`;
  } else {
    return inDayTime;
  }
};

export const orderTimeFinish = (data: number, showDay: boolean = true): string => {
  const date = new Date(data);
  const inDayTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  if (!isToday(date)) {
    if (!showDay) {
      return inDayTime
    }
    const day = date.getDate();
    const month = dates.caseMonths[date.getMonth()];
    return `${day} ${month} в ${inDayTime}`;
  } else {
    if (showDay) {
      return `Сегодня в ${inDayTime}`;
    }
    return inDayTime;
  }
};

export const orderTimeInProgressView = (data): orderTimeInProgressDTO => {
  const date = new Date(data);
  const timeInProgress = new Date().getTime() - date.getTime();
  const hours = Math.floor(timeInProgress / 3600000);
  const minutes = Math.floor(timeInProgress / 60000) - hours * 60;
  const isHighlighted = timeInProgress > 1560000;

  if (timeInProgress < 60000) {
    return { isHighlighted, value: `< 1 мин.` };
  }
  if (timeInProgress >= 86400000) {
    return { isHighlighted, value: `> 1 д.` };
  } else {
    return { isHighlighted, value: `${hours < 1 ? '' : `${hours} ч `}${minutes} мин` };
  }
};

export const arrayToObjectDTO = (data): any => {
  let result = {};
  for (let i = 0; i < data.length; i++) {
    result[data[i].id] = data[i];
  }
  return result;
};

export const flushInput = (): void => {
  setQuery('');
};
