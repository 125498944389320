import React, { FC } from 'react';
import styles from './Paginator.module.scss';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/LeftArrow.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/RightArrow.svg';
import { useStore } from 'effector-react';
import { PaginatorProps } from './interfaces';

const Paginator: FC<PaginatorProps> = ({ onPrevPage, onNextPage, pagesAmountStore, pageNumStore }) => {
  const pageNum = useStore(pageNumStore);
  const pagesAmount = useStore(pagesAmountStore);

  return (
    <div className={styles.container}>
      <LeftArrowIcon className={styles.icon} onClick={onPrevPage} />
      <p className={styles.values}>{pageNum} - {pagesAmount}</p>
      <RightArrowIcon className={styles.icon} onClick={pageNum < pagesAmount ? onNextPage : undefined} />
    </div>
  );
};

export default Paginator;
