import OrderRow from './OrderRow';

const OrderRows = ({ data }) => {
  return (
    <div>
      {data.map((item) => (
        <OrderRow data={item} />
      ))}
    </div>
  );
};

export default OrderRows;
