import React from 'react';
import styles from './OrderPopup.module.scss';
import useOrderPopup from './useOrderPopup';
import { ReactComponent as MoreIcon } from 'assets/icons/More.svg';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $orderData, $orderId, onSendToAloha } from 'models/Order';

const OrderPopup = () => {
  const { containerRef, listRef } = useOrderPopup();
  const orderId = useStore($orderId);
  const data = useStore($orderData);

  const handleSend = () => {
    onSendToAloha();
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <MoreIcon className={styles.icon} />
      <div className={styles.list} ref={listRef}>
        {data.serviceData.showSendToAlohaButton && (
          <div className={styles.item} onClick={handleSend}>Переотправить</div>
        )}
        <Link to={`/hub/orders/order/${orderId}/status`}>
          <div className={styles.item}>Изменить статус</div>
        </Link>
      </div>
    </div>
  );
};

export default OrderPopup;
