import { $user } from './config';
import { LoginPayload, UserData } from 'pages/Login/interfaces';
import { setError } from 'models/Hub';

export const loginTelegram = async (phone: string): Promise<UserData> => {
  try {
    const { data } = await $user.post('/TelegramLogin', { phone });
    if (!data.success) {
      setError(data.message);
      return {
        authenticated: false,
        roleName: '',
        userCapabilities: { access: {}, departmentsMainSelect: [] },
      };
    }
    return data.result;
  } catch (err) {
    return {
      authenticated: false,
      roleName: '',
      userCapabilities: { access: {}, departmentsMainSelect: [] },
    };
  }
};

export const login = async (loginData: LoginPayload): Promise<UserData> => {
  try {
    const { data } = await $user.post('/Login', {
      ...loginData,
    });
    if (!data.success) {
      setError(data.message);
      return {
        authenticated: false,
        roleName: '',
        userCapabilities: { access: {}, departmentsMainSelect: [] },
      };
    }
    return data.result;
  } catch (err) {
    return {
      authenticated: false,
      roleName: '',
      userCapabilities: { access: {}, departmentsMainSelect: [] },
    };
  }
};

export const checkUser = async () => {
  try {
    const { data } = await $user.post('/UserCapabilities');
    if (!data.success) {
      setError(data.message);
      return {
        authenticated: false,
        roleName: '',
        userCapabilities: { access: {}, departmentsMainSelect: [] },
      };
    }
    return data.result;
  } catch (err) {
    return {
      authenticated: false,
      roleName: '',
      userCapabilities: { access: {}, departmentsMainSelect: [] },
    };
  }
};

export const logout = async () => {
  try {
    const { data } = await $user.post('/Logout');
    const { success } = data;
    if (!data.success) {
      setError(data.message);
      throw new Error();
    }
    return success;
  } catch (err) {
    return { success: false };
  }
};
