import { FC, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import { get, set } from 'idb-keyval';
import Icons from './Icons';
import { Navigation } from '../../constants/Navigation';
import { logout } from 'shared/api/REST/UserApi';

const Header: FC = () => {
  const [isMuted, setMute] = useState<boolean | null>(null);

  useEffect(() => {
    const storageHandler = async () => {
      if (isMuted === null) {
        const storageValue = await get('isMuted');
        if (storageValue === undefined) {
          await set('isMuted', false);
          setMute(false);
        } else {
          setMute(storageValue);
        }
      } else {
        await set('isMuted', !isMuted);
        setMute((prev) => !prev);
      }
    };
    storageHandler();
  }, []);

  const soundToggle = async () => {
    const value: boolean | undefined = (await get('isMuted')) ?? isMuted;
    await set('isMuted', !value);
    setMute(!value);
  };

  return (
    <header className={styles.container}>
      <div className={styles.menu}>
        <span className={styles.logo}>Delivery Hub</span>
        {Navigation.map(({ icon, navTitle, slug }, key) => (
          <NavLink
            className={({ isActive }) =>
              isActive ? `${styles.menu_item} ${styles.current_tab}` : styles.menu_item
            }
            to={slug}
            key={key}
          >
            <span className={styles.menu_item_icon}>
              <Icons name={icon} />
            </span>
            <span>{navTitle}</span>
          </NavLink>
        ))}
      </div>
      <div className={styles.icons}>
        <span className={styles.icon} onClick={soundToggle}>
          {isMuted ? <Icons name='BellOff' /> : <Icons name='Bell' />}
        </span>
        <Link to='/logout'>
          <span className={styles.icon}>
            <Icons name='Logout' />
          </span>
        </Link>
      </div>
    </header>
  );
};

export default Header;
