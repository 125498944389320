import { FC } from 'react';
import { useGate, useStore } from 'effector-react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { ReportsGate } from 'models/Reports';
import styles from './Reports.module.scss';
import OperatorReports from './components/OperatorReports';
import ChefReports from './components/ChefReports';
import HubTitle from 'shared/components/SectionTitle/SectionTitle';
import Button from 'shared/ui/Button';
import { $allowedRestaurants } from 'models/Auth';

const Reports: FC = () => {
  useGate(ReportsGate);

  return (
    <section className={styles.container}>
      <div className='section__head'>
        <HubTitle title='Отчёты' />
        <div className='section_filters'>
          <NavLink to='operator'>
            {({ isActive }) => (
              <Button
                className={styles.nav_button}
                text='Оператор'
                theme={isActive ? 'highlighted' : 'default'}
              />
            )}
          </NavLink>
          <NavLink to='chef'>
            {({ isActive }) => (
              <Button
                className={styles.nav_button}
                text='Шеф'
                theme={isActive ? 'highlighted' : 'default'}
              />
            )}
          </NavLink>
        </div>
      </div>
      <Routes>
        <Route index element={<Navigate to='operator' />} />
        <Route path='operator' element={<OperatorReports />} />
        <Route path='chef' element={<ChefReports />} />
      </Routes>
    </section>
  );
};

export default Reports;
