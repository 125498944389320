import { ChangeEvent, useState } from 'react';
import { saveDelivery } from 'shared/api/OrderEditApi';

const useDelivery = (orderId: number) => {
  const [paying, setPaying] = useState<string>('cash');
  const [price, setPrice] = useState<string>('');

  const changePaying = (value: string) => {
    setPaying(value);
  };

  const changePrice = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numberedValue = value.replace(/\D/g, '');
    if (numberedValue === '0' || numberedValue.length > 4) {
      return;
    }
    return setPrice(numberedValue);
  };

  const saveData = async () => {
    const { success } = await saveDelivery(paying, price);
    if (success) {
      //close();
    }
  };

  const data = { price: 4600, type: 'url' };

  return { changePaying, changePrice, saveData, data, paying, price };
};

export default useDelivery;
