import { sample } from 'effector';
import { $hubConnection, $sources, $statuses } from 'models/Hub';
import {
  $debouncedQuery, $orders,
  $orderSources, $ordersSorting,
  $orderStatuses,
  $pageNum,
  $restaurant,
  $searchCategory,
  $sorting,
  getDashboardFx,
  getOrdersDataFx,
  getQueriedOrdersFx,
  OrdersGate,
  restaurantFx,
  sortingFx,
} from '.';
import { arrayToObjectDTO } from 'pages/Orders/utils/Orders';
import { $allDishes, $allSorting } from 'models/StopList';
import { sortTable } from 'models/StopList/utils';

sample({
  clock: $hubConnection,
  source: { hubConnection: $hubConnection },
  filter: ({ hubConnection }) => !!hubConnection,
  target: getDashboardFx,
});

sample({
  clock: $sources,
  fn: arrayToObjectDTO,
  target: $orderSources,
});

sample({
  clock: $statuses,
  fn: arrayToObjectDTO,
  target: $orderStatuses,
});

sample({
  clock: [$hubConnection, $pageNum, $statuses],
  source: { hubConnection: $hubConnection, pageNum: $pageNum, statuses: $statuses },
  filter: ({ hubConnection, statuses }) => !!hubConnection && !!statuses,
  target: getOrdersDataFx,
});

sample({
  clock: $restaurant,
  source: { hubConnection: $hubConnection, restaurant: $restaurant },
  filter: ({ hubConnection }) => !!hubConnection,
  target: restaurantFx,
});

sample({
  clock: $ordersSorting,
  source: { data: $orders, sorting: $ordersSorting },
  fn: sortTable,
  target: $orders,
});

sample({
  clock: $sorting,
  source: { hubConnection: $hubConnection, sorting: $sorting },
  filter: ({ hubConnection }) => !!hubConnection,
  target: sortingFx,
});

sample({
  clock: $debouncedQuery,
  source: { category: $searchCategory, hubConnection: $hubConnection, query: $debouncedQuery },
  filter: ({ hubConnection }) => !!hubConnection,
  fn: ({ category, hubConnection, query }) => ({ category, hubConnection, query: query.length >= 3 ? query : '' }),
  target: getQueriedOrdersFx,
});
