import { FC } from 'react';
import { EditUserProps } from '../interfaces/ICreateOrder';

const EditUser: FC<EditUserProps> = ({
  //closeAll,
  loadUserData,
  handlePhone,
  saveData,
  phone,
}) => {
  return (
    <div>
      <input className='input' onChange={handlePhone} placeholder='Имя' value={phone} />
      <input className='input' onChange={handlePhone} placeholder='Номер телефона' value={phone} />
      <button className='button__highlighted' onClick={() => loadUserData(phone)}>
        Далее
      </button>
    </div>
  );
};

export default EditUser;
