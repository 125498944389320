import { OrderTableStyles } from 'components/Table/TablesStyles';
import globalStyles from 'components/Table/styles/Row.module.scss';
import styles from './OrderRow.module.scss';

const OrderRow = ({ data }) => {
  return (
    <div className={globalStyles.container}>
      <div
        className={styles.dish}
        style={{ gridTemplateColumns: OrderTableStyles.row }}
      >
        <p>{data.title}</p>
        <p>x{data.amount}</p>
        <p className={styles.price}>{data.price}</p>
      </div>
      {!!data.additives.length && (
        <div className={styles.additives}>
          {data.additives.map(item => (
            <div className={styles.additive}><p>+ {item.title}</p><p>{item.price}</p></div>
          ))}
        </div>
      )}
      {data.request && <p className={styles.comment}>«{data.request}»</p>}
    </div>
  );
};

export default OrderRow;
