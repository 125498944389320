const Fetch = (baseUrl: string) => {
  const get = async (slug: string) => {
    const response = await fetch(baseUrl + slug, {
      credentials: 'include',
    });
    const data = await response.json();
    return { data };
  };
  const post = async (slug: string, body: object = {}) => {
    const response = await fetch(baseUrl + slug, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return { data };
  };
  return { get, post };
};

export default Fetch;
