import { FC } from 'react';
import styles from '../Reports.module.scss';
import { Cells, ColumnInfos, ReportsTableProps, Rows, SubRows } from '../interfaces';
import Icons from '../../../assets/Icons';
import Button from 'shared/ui/Button';
import { exportToXLSX } from '../utils';
import { useStore } from 'effector-react';

const ReportsTable: FC<ReportsTableProps> = ({
  changeSorting,
  markup,
  storeData,
  storeSorting,
}) => {
  const data = useStore(storeData);
  const sorting = useStore(storeSorting);

  const { columnInfos, rows } = data;
  return (
    <>
      <div className='table__titles' style={{ gridTemplateColumns: markup }}>
        {columnInfos?.map(({ id, title }: ColumnInfos, cellIndex: number) => (
          <div className='table__title_container' key={id} onClick={() => changeSorting(cellIndex)}>
            <p className='table__title_text'>{title}</p>
            {sorting.cellIndex === cellIndex ? <Icons name={`${sorting.type}Icon`} /> : <></>}
          </div>
        ))}
      </div>
      <div style={{ overflow: 'auto', maxHeight: '550px' }}>
        {rows?.map(({ cells }: Rows, rowIndex: number) => (
          <div className='table__item' key={rowIndex} style={{ gridTemplateColumns: markup }}>
            {cells.map(({ id, subRows }: Cells) => (
              <div key={id}>
                {subRows.map(({ value, color }: SubRows, key: number) => (
                  <p key={key}>{value}</p>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      <Button
        className={styles.button}
        onClick={() => exportToXLSX(data, 'Список предзаказов')}
        text='Скачать'
        theme='highlighted'
      />
    </>
  );
};

export default ReportsTable;
