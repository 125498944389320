import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'shared/api/REST/UserApi';
import Loader from 'shared/ui/Loader';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const Logout = async () => {
      const success = await logout();
      if (success) {
        navigate('/login', { replace: true });
      }
    };
    Logout();
  }, []);

  return <Loader />;
};

export default Logout;
