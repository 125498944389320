import { FC } from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthRoutes, PrivateRoutes } from './routing/Routes';
import { IRoute } from 'shared/interfaces/IRouter';
import Hub from './pages/Hub';
import Order from 'components/Order';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import Auth from 'pages/Auth';

const App: FC = () => {
  return (
    <ErrorBoundary>
      <Router>
        <main className='page__main'>
          <Routes>
            <Route path='/' element={<Auth />}>
              {AuthRoutes.map((route: IRoute, key: number) => (
                <Route key={key} {...route} />
              ))}
              <Route path='/hub' element={<Hub />}>
                {PrivateRoutes.map((route: IRoute, key: number) => (
                  <Route key={key} {...route} />
                ))}
              </Route>
            </Route>
          </Routes>
        </main>
        <Routes>
          <Route path='/hub/archive/order/*' element={<Order />} />
          <Route path='/hub/orders/order/*' element={<Order />} />
          <Route path='*' element={<></>} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
