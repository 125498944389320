import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { CountersTabs } from 'pages/Orders/interfaces/IOrders';
import {
  getDashboardData,
  getOrdersData,
  getQueriedOrders,
  sendRestaurant,
  sendSorting,
} from 'shared/api/Signal/Orders';
import { SelectorItem } from 'shared/components/Selector/interfaces';
import {
  getDashboardPayload,
  getOrdersDataPayload,
  getQueriedOrdersPayload,
  sendRestaurantPayload,
  sendSortingPayload,
} from 'shared/api/interfaces/ISignalAPI';
import { debounce } from 'patronum/debounce';
import { StopListSorting } from 'models/StopList/utils';
import { setAllSorting } from 'models/StopList';

export const OrdersGate = createGate();
export const prevPage = createEvent();
export const nextPage = createEvent();
export const setCounters = createEvent<CountersTabs[]>();
export const setHasNextPage = createEvent<boolean>();
export const setFilter = createEvent<number>();
export const setRestaurant = createEvent<SelectorItem>();
export const setSorting = createEvent<SelectorItem>();
export const setOrders = createEvent<any[]>();
export const setOrdersSorting = createEvent<any>();
export const setPageNum = createEvent<number>();
export const setSearchCategory = createEvent<SelectorItem>();
export const setTotalCounter = createEvent<number>();
export const setQuery = createEvent<string>();
export const restaurantFx = createEffect<sendRestaurantPayload, void>(sendRestaurant);
export const sortingFx = createEffect<sendSortingPayload, void>(sendSorting);
export const getOrdersDataFx = createEffect<getOrdersDataPayload, void>(getOrdersData);
export const getDashboardFx = createEffect<getDashboardPayload, void>(getDashboardData);
export const getQueriedOrdersFx = createEffect<getQueriedOrdersPayload, void>(getQueriedOrders);

export const $counters = createStore<CountersTabs[]>([]).on(
  setCounters,
  (_, data: CountersTabs[]) => data,
);
export const $hasNextPage = createStore<boolean>(false).on(
  setHasNextPage,
  (_, hasNextPage: boolean) => hasNextPage,
);
export const $totalCounter = createStore<number | null>(null).on(
  setTotalCounter,
  (_, amount: number) => amount,
);
export const $filterId = createStore<number | null>(null).on(
  setFilter,
  (_, filter: number) => filter,
);
export const $restaurant = createStore<SelectorItem>({ id: 1, title: 'Все рестораны' }).on(
  setRestaurant,
  (_, restaurant: SelectorItem) => restaurant,
);
export const $searchCategory = createStore<SelectorItem>({ id: 0, title: 'По умолчанию' }).on(
  setSearchCategory,
  (_, category: SelectorItem) => category,
);
export const $sorting = createStore<SelectorItem>({ id: 0, title: 'По умолчанию' }).on(
  setSorting,
  (_, sorting: SelectorItem) => sorting,
);
export const $orders = createStore<any[]>([]).on(setOrders, (_, orders: any[]) => orders);
export const $ordersSorting = createStore<any>(StopListSorting);
//.on(setOrdersSorting, (_, sorting) => sorting);
export const $pageNum = createStore<number>(1)
  .on(prevPage, (pageNum: number) => (pageNum > 1 ? pageNum - 1 : pageNum))
  .on(nextPage, (pageNum: number) => pageNum + 1);
export const $pagesAmount = createStore<number>(1);
export const $orderStatuses = createStore(null);
export const $orderSources = createStore(null);
export const $query = createStore<string>('').on(setQuery, (_, query: string) => query);

export const setDebouncedQuery = debounce({
  source: $query,
  timeout: 350,
});

export const $debouncedQuery = createStore<string>('').on(setDebouncedQuery, (_, debouncedQuery: string) => debouncedQuery);
