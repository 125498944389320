import { Sorting } from 'pages/Reports/interfaces';

export const defaultDates = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);
  const endDate = new Date();
  endDate.setDate(2);
  endDate.setMonth(endDate.getMonth() + 1);

  return [startDate, endDate];
};

export const ReportsTable = {
  columnInfos: [],
  rows: [],
  saveDisabled: true,
  title: 'Загрузка...',
  untouchable: false,
};

export const ReportsSorting: Sorting = {
  cellIndex: -1,
  type: 'Descending',
};
