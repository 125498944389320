import { ISaveDataId } from '../../interfaces/ISaveData';
import { $data } from './REST/config';

export const saveDelivery = async (paying: string, price: string): Promise<ISaveDataId> => {
  /*const { data } = await axios.post<ISaveData>("/api/savedelivery", {
        price, type
      });
      return data;*/ 

  return { success: true };
};

export const getUser = async (phone: string) => {
  /*const { data } = await $data.post<ISaveData>("/getuser", {
    phone,
  });

  return data;*/
  return null;
};
