import { StopListTableStyles } from 'components/Table/TablesStyles';
import globalStyles from 'components/Table/styles/Row.module.scss';
import styles from './StoppedRows.module.scss';
import Cell from 'components/Table/components/Cell';
import SearchAvailability from './components/SearchAvailability';
import RemoveFromStopList from './components/RemoveFromStopList';
import DishCell from '../DishCell';
import { useStore } from 'effector-react';
import { $stoppedDishes } from 'models/StopList';

const StoppedRows = () => {
  const data = useStore($stoppedDishes);

  return (
    <div>
      {data.map(({ id, name, addDate, type }) => (
        <div className={globalStyles.container} style={{ gridTemplateColumns: StopListTableStyles.stopped }}>
          <Cell className={styles.cell} data={id} />
          <DishCell data={name} />
          <Cell className={styles.cell} data={addDate} />
          <Cell className={styles.cell} data={type} />
          <SearchAvailability />
          <RemoveFromStopList />
        </div>
      ))}
    </div>
  );
};

export default StoppedRows;
