import { FC } from 'react';
import styles from './EditContainer.module.scss';
import Icons from 'assets/Icons';
import { useNavigate, useLocation } from 'react-router-dom';
import EditHeader from 'components/Order/components/OrderHeader/OrderHeader';

interface EditContainerProps {
  children: JSX.Element;
  close?: () => void;
  saveData: () => void;
  title: string;
}

const EditContainer: FC<EditContainerProps> = ({ children, close, saveData, title }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onCancel = () => {
    return navigate(-1);
  };

  const onClose = () => {
    return navigate('../../../../', { replace: true });
  };

  return (
    <div className={styles.container}>
      <EditHeader title={title} />
      {children}
      <div className={styles.buttons}>
        <button className={styles.button} onClick={onCancel}>
          Назад без сохранения
        </button>
        <button className={styles.button_highlighted} onClick={saveData}>
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default EditContainer;
