import { FC } from 'react';
import styles from '../../Reports.module.scss';
import ReportsTable from '../ReportsTable';
import Calendar from 'shared/components/Calendar';
import Selector from 'shared/components/Selector';
import {
  $operatorDates,
  $operatorPreorders,
  $operatorSorting,
  $restaurant,
  setOperatorDates,
  getOperatorData,
  setRestaurant,
  setOperatorSorting,
} from 'models/Reports';
import { useStore } from 'effector-react';
import Button from 'shared/ui/Button';
import { $allowedRestaurants } from 'models/Auth';

const OperatorReports: FC = () => {
  const operatorPreorders = useStore($operatorPreorders);
  const allowedRestaurants = useStore($allowedRestaurants);

  return (
    <div className={styles.table__section}>
      <div className={styles.table__calendars}>
        <Calendar index={0} label='От' onSelect={setOperatorDates} storeDate={$operatorDates} />
        <Calendar index={1} label='До' onSelect={setOperatorDates} storeDate={$operatorDates} />
        {allowedRestaurants.length > 1 && (
          <Selector
            onSelect={setRestaurant}
            storeData={$allowedRestaurants}
            storeValue={$restaurant}
          />
        )}
        <Button onClick={getOperatorData} text='Применить' theme='highlighted' />
      </div>
      <h2 className={styles.table__title}>{operatorPreorders.title}</h2>
      <ReportsTable
        changeSorting={setOperatorSorting}
        markup='150px 200px 200px 200px 200px 200px 200px 200px'
        storeData={$operatorPreorders}
        storeSorting={$operatorSorting}
      />
    </div>
  );
};

export default OperatorReports;
