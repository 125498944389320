import { FC } from 'react';
import { DishItem } from './IDish';
import styles from './Dish.module.scss';

interface DishProps {
  data: DishItem;
}

const Dish: FC<DishProps> = ({ data }) => {
  return (
    <div className={styles.container}>
      <img className={styles.poster} src={data.image} alt='' />
      <div className={styles.info}>
        <p className={styles.title}>{data.title}</p>
        <p className='light_subtitle'>{data.params}</p>
        <button className={`button__highlighted ${styles.button}`}>Добавить в заказ</button>
      </div>
    </div>
  );
};

export default Dish;
