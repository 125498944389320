import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateOrderFormProps } from '../interfaces/ICreateOrder';

const useCreateOrder = (): CreateOrderFormProps => {
  const navigate = useNavigate();
  const [sectionId, setSectionId] = useState<number>(1);

  const changeSection = (action: string) => {
    if (action === 'prev' && sectionId > 1) {
      setSectionId((prev) => prev - 1);
    }

    if (action === 'next' && sectionId < 6) {
      setSectionId((prev) => prev + 1);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  return {
    changeSection,
    onBack,
  };
};

export default useCreateOrder;
