import { Navigate } from 'react-router-dom';
import { IRoute } from 'shared/interfaces/IRouter';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Archive from '../pages/Archive';
import DeliveryAreas from '../pages/DeliveryAreas';
import Reports from '../pages/Reports';
import StopList from '../pages/StopList';
import Orders from '../pages/Orders';

export const AuthRoutes: IRoute[] = [
  {
    path: '',
    element: <Navigate to='/hub/orders' />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
];

export const PrivateRoutes: IRoute[] = [
  {
    path: 'orders/*',
    element: <Orders />,
  },
  {
    path: 'archive/*',
    element: <Archive />,
  },
  {
    path: 'stoplist',
    element: <StopList />,
  },
  {
    path: 'reports/*',
    element: <Reports />,
  },
  {
    path: 'areas',
    element: <DeliveryAreas />,
  },
];
