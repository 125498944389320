import { sample } from 'effector';
import {
  $allowRedirect,
  $loginData, $userData,
  checkUserFx,
  loginFx, loginGate,
  onLogin,
  TelegramLogin,
  telegramLoginFx,
} from '.';
import { HubGate } from 'models/Hub';
import { LoginDTO } from '../../pages/Login/utils';

sample({
  clock: HubGate.open,
  target: checkUserFx,
});

sample({
  clock: onLogin,
  source: $loginData,
  fn: LoginDTO,
  target: loginFx,
});

sample({
  clock: TelegramLogin,
  source: $loginData,
  fn: (sourceData) => sourceData.login,
  target: telegramLoginFx,
});

sample({
  clock: [loginFx.doneData, telegramLoginFx.doneData],
  fn: (clockData) => {
    //console.log(clockData);
    return clockData.authenticated;
  },
  target: $allowRedirect,
});

//$userData.watch(console.log);