import React from 'react';
import { IEditDishes } from 'interfaces/IOrderEdit';

const useDishes = (orderId: number): IEditDishes[] => {
  const data: IEditDishes[] = [
    {
      amount: 1,
      additives: [
        {
          isActive: true,
          id: 3,
          price: '150 ₽',
          title: 'Свежая клубника',
        },
        {
          isActive: false,
          id: 5,
          price: '450 ₽',
          title: 'На овсяном молоке',
        },
      ],
      comment: 'Смешайте, но не взбалтывайте',
      id: 2,
      methods: [
        { id: 1, isActive: true, title: 'На гриле' },
        { id: 2, isActive: false, title: 'На пару' },
      ],
      price: '2 200 ₽',
      title: 'Каша пшённая',
    },
  ];
  return data;
};

export default useDishes;
