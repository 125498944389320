export const isToday = (date) => {
  const paramsDate = new Date(date);
  const todayDate = new Date();
  const paramsDay = paramsDate.getDate();
  const paramsMonth = paramsDate.getMonth();
  const paramsYear = paramsDate.getFullYear();
  const todayDay = todayDate.getDate();
  const todayMonth = todayDate.getMonth();
  const todayYear = todayDate.getFullYear();
  if (paramsDay === todayDay && paramsMonth === todayMonth && paramsYear === todayYear) {
    return true;
  }
  return false;
};

export const paramsDateDTO = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const handleDate = (prev, { date, index }): Date[] => {
  let result = [...prev];
  result[index] = date;
  return result;
};
