import { $info } from 'shared/api/REST/config';
import { setError } from 'models/Hub';
import { paramsDateDTO } from 'shared/utils/Dates';

export const getArchiveOrders = async (params): Promise<any> => {
  try {
    const { category, dates, pageNum, query, restaurant } = params;
    const startDate = paramsDateDTO(dates[0]);
    const endDate = paramsDateDTO(dates[1]);
    const { data } = await $info.get(
      `/OrderHistory?restaurantId=${restaurant.id}&searchValue=${query}&type=${category.id}&pageNum=${pageNum}&pageSize=11&startDate=${startDate}&endDate=${endDate}`
    );
    const { result, success } = data;
    if (!success) {
      setError(data.message);
      throw new Error();
    }
    return result;
  } catch (err) {
    return {
      columnInfos: [],
      rows: [],
      saveDisabled: true,
      stats: [],
      title: '',
      untouchable: false,
    };
  }
};
