import React from 'react';
import styles from './DishCell.module.scss';

const DishCell = ({data}) => {
  return (
    <div className={styles.container}>
      <p>{data}</p>
      <p className={`light_subtitle ${styles.amount}`}>300 мл</p>
    </div>
  );
};

export default DishCell;
