import { useState } from 'react';
import Categories from './components/Categories';
import styles from './styles/StopList.module.scss';
import { StopListTableStyles } from 'components/Table/TablesStyles';
import { StopListTableTitles } from 'shared/constants/TablesTitles';
import Table from 'components/Table';
import StoppedRows from './components/StoppedRows';
import AllRows from './components/AllRows';
import {
  $allSorting,
  $restaurant, $stoppedDishes,
  $stoppedSorting,
  setAllSorting,
  setStoppedSorting,
} from 'models/StopList';
import { useStore } from 'effector-react';
import StopListHeader from './components/StopListHeader';
import Surok from 'assets/icons/StopList/Surok.jpg';

const StopList = () => {
  //return <h1>Раздел находится в разработке.</h1>;
  const restaurant = useStore($restaurant);
  const [categories, setCategories] = useState<number[]>([]);
  const typesStopped = ['number', 'string', 'number', 'string'];
  const typesAll = ['number', 'string'];

  return (
    <section>
      <StopListHeader />
      <div className={styles.wrapper}>
        <Categories categories={categories} setCategories={setCategories} />
        <div className={styles.tables}>
          {restaurant && <p className={styles.choose}>Выберите ресторан из списка</p>}
          {restaurant && <img className={styles.surok} src={Surok} />}
          {!restaurant && (
            <>
              <div className={styles.stoppedSection}>
                <h2>Блюда на стопе</h2>
                <Table
                  changeSorting={setStoppedSorting}
                  storeSorting={$stoppedSorting}
                  markup={StopListTableStyles.stopped}
                  rows={<StoppedRows />}
                  titles={StopListTableTitles.stopped}
                />
              </div>
              <div className={styles.allSection}>
                <h2>Все блюда</h2>
                <Table
                  changeSorting={setAllSorting}
                  storeSorting={$allSorting}
                  markup={StopListTableStyles.all}
                  rows={<AllRows />}
                  titles={StopListTableTitles.all}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default StopList;
