import { FC, Dispatch, ChangeEvent, SetStateAction, useState } from 'react';
import EditHeader from '../OrderHeader/OrderHeader';
import styles from './AddDish.module.scss';
//import Input from '../SearchForm';
import useDishes from './useDishes';
import Dish from './Dish';

interface AddDishProps {
  close: Dispatch<SetStateAction<boolean>>;
}

const AddDish: FC<AddDishProps> = ({ close }) => {
  const [query, setQuery] = useState<string>('');
  const dishes = useDishes(query);

  return (
    <section className='edit_area'>
      <EditHeader title='Добавить блюдо' />
      <div className={styles.dish_section}>
        {/*<Input*/}
        {/*  placeholder='Поиск блюда...'*/}
        {/*  //handleQuery={handleQuery}*/}
        {/*  query={query}*/}
        {/*/>*/}
        <div className={styles.dishes_results}>
          {dishes.map((item) => (
            <Dish data={item} key={item.id} />
          ))}
        </div>
      </div>
      {/*<EditButtons close={Close} />*/}
    </section>
  );
};

export default AddDish;
