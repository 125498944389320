import { FC, memo } from 'react';
import styles from './OrderInfo.module.scss';

const OrderInfo: FC<any> = ({ className = {}, onClick = () => undefined, title, value }) => {
  return (
    <span className={`${styles.container}${className ? ` ${className}` : ''}`}>
      <p className='light_subtitle'>{title}:&nbsp;</p>
      <p className={className} onClick={onClick}>{value}</p>
    </span>
  );
};

export default memo(OrderInfo);