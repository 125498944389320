import { FC } from 'react';
import styles from '../styles/CreateOrder.module.scss';
import EditUser from './EditUser';
import useEditUser from '../hooks/useEditUser';
import { CreateOrderFormProps } from '../interfaces/ICreateOrder';
import EditHeader from '../../Order/components/OrderHeader/OrderHeader';

const CreateOrderForm: FC<CreateOrderFormProps> = ({ changeSection, onBack }) => {
  return (
    <>
      <p className='light_subtitle'>Шаг 1/6 (Заполнение данных пользователя)</p>
      {<EditUser {...useEditUser()} />}
      <footer className={styles.footer}>
        <button className='button' onClick={onBack}>
          Назад
        </button>
        <button className='button__highlighted' onClick={() => changeSection('next')}>
          Далее
        </button>
      </footer>
    </>
  );
};

export default CreateOrderForm;
