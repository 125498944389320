import { FC, memo } from 'react';
import styles from './Button.module.scss';
import { ButtonProps } from './interfaces';
import { classNames } from './utils';

const Button: FC<ButtonProps> = ({
                                   className = '',
                                   disabled = false,
                                   onClick,
                                   style = {},
                                   text,
                                   theme = 'default',
                                 }) => {
  return (
    <button
      className={`${styles.container} ${classNames[theme]}${className ? ' ' + className : ''}${disabled ? ` ${styles.disabled}` : ''}`}
      onClick={!disabled ? onClick : undefined}
      style={style}
    >
      {text}
    </button>
  );
};

export default memo(Button);
