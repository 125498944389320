import { sample } from 'effector';
import { $orderId } from 'models/Order';
import { changeRestaurantFx, changeStatusFx, setRestaurant, setStatus } from 'models/EditOrder/index';

sample({
  clock: setRestaurant,
  source: { orderId: $orderId },
  fn: (sourceData, restaurantNo: number) => ({ ...sourceData, restaurantNo }),
  target: changeRestaurantFx,
});

sample({
  clock: setStatus,
  source: { orderId: $orderId },
  fn: (sourceData, statusId: number) => ({ ...sourceData, statusId }),
  target: changeStatusFx,
});