import styles from './Days.module.scss';

const Days = () => {
  return (
    <tr className={styles.container}>
      <th className={styles.day}>Пн</th>
      <th className={styles.day}>Вт</th>
      <th className={styles.day}>Ср</th>
      <th className={styles.day}>Чт</th>
      <th className={styles.day}>Пт</th>
      <th className={styles.day}>Сб</th>
      <th className={styles.day}>Вс</th>
    </tr>
  );
};

export default Days;