import { render } from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './models/init';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2cf652e9356f49be8dc5ad1add69b31c@sentry.coffeemania.io/5',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

render(<App />, document.getElementById('root'));
