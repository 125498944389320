import { FC } from 'react';
import styles from './Checkbox.module.scss';
import { CheckboxProps } from './interfaces';

const Checkbox: FC<CheckboxProps> = ({ checked, id, label }) => {
  return (
    <>
      <input
        checked={checked}
        id={id}
        name='filter'
        onClick={(e: any) => e.stopPropagation()}
        className={styles.custom_checkbox}
        type='checkbox'
      />
      <label htmlFor={id}>{label}</label>
    </>
  );
};

export default Checkbox;
