import { ReactComponent as RemoveIcon } from 'assets/icons/StopList/Remove.svg';
import styles from './RemoveFromStopList.module.scss';

const RemoveFromStopList = () => {
  return (
    <div className={styles.container}>
      <RemoveIcon />
    </div>
  );
};

export default RemoveFromStopList;
