import React from 'react';
import styles from './OrderFooter.module.scss';
import Button from 'shared/ui/Button';
import {
  $isDisableAlohaButton,
  $isDisableStatusButton,
  $isDisableWhiteCabsButton,
  $orderData,
  $orderId,
  onSendToAloha,
  onSendToWhiteCabs,
  sendToWhiteCabsFx,
  setNextStatus,
} from 'models/Order';
import { useStore } from 'effector-react';
import { $orderStatuses } from 'models/Orders';

const OrderFooter = () => {
  const data = useStore($orderData);
  const whiteCabsSendButton = data.serviceData.showWhiteCabsSendButton;
  const statusButton = data.serviceData.fulfillmentStatusButton;
  const orderStatuses = useStore($orderStatuses);
  const isDisableAlohaButton = useStore($isDisableAlohaButton);
  const isDisableStatusButton = useStore($isDisableStatusButton);
  const isDisableWhiteCabsButton = useStore($isDisableWhiteCabsButton);
  const orderId = useStore($orderId);

  return (
    <div className={styles.container}>
      {statusButton ? (
        <Button className={styles.statusButton} disabled={isDisableStatusButton === orderId} onClick={setNextStatus}
                style={{ backgroundColor: orderStatuses[statusButton.id]?.color }} text={statusButton.title} />
      ) : <div />}
      <Button className={styles.button} disabled={true} text='Печать этикеток' theme='grey' />
      {whiteCabsSendButton ? (
        <Button className={styles.button} disabled={isDisableWhiteCabsButton === orderId} onClick={onSendToWhiteCabs}
                text='Отправить в WHITECABS'
                theme='black' />
      ) : <div />}
    </div>
  );
};

export default OrderFooter;
