import { combine } from 'effector';
import { getStatusesFx } from 'models/Hub';
import { getDashboardFx, getOrdersDataFx, restaurantFx, sortingFx } from 'models/Orders';
import {
  getChefDeliveryReportsFx,
  getChefPreordersFx,
  getOperatorPreordersFx,
} from 'models/Reports';
import { getArchiveDataFx } from 'models/Archive';
import { checkUserFx } from 'models/Auth';

export const $loader = combine(
  [
    checkUserFx.pending,
    getArchiveDataFx.pending,
    getDashboardFx.pending,
    getOrdersDataFx.pending,
    restaurantFx.pending,
    sortingFx.pending,
    getOperatorPreordersFx.pending,
    getChefDeliveryReportsFx.pending,
    getChefPreordersFx.pending,
  ],
  (arr) => arr.some(Boolean)
);

export const $areOrdersLoading = combine([getStatusesFx.pending, getOrdersDataFx.pending], (arr) =>
  arr.some(Boolean)
);
