import { $allowRedirect, setAllowRedirect } from 'models/Auth';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

const useLogin = () => {
  const allowRedirect = useStore($allowRedirect);
  const navigate = useNavigate();

  useEffect(() => {
    if (allowRedirect) {
      const path = '/hub/orders';
      return navigate(path, { replace: true });
    }
  }, [allowRedirect]);

  // const login = () => {
  //   onLogin();
  //   const path = '/hub/orders';
  //   //return navigate(path, { replace: true });
  // };
  //
  // const telegramLogin = () => {
  //   TelegramLogin();
  //   const path = '/hub/orders';
  //   //return navigate(path, { replace: true });
  // };

  //return { login, telegramLogin };
};

export default useLogin;