import styles from './OrderAdditives.module.css';
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';
import { ReactComponent as RestrictIcon } from 'assets/icons/Restrict.svg';

const OrderAdditives = ({ title, value }) => {
  return (
    <div className={styles.container}>
      {value ? <CheckIcon /> : <RestrictIcon />}
      <p className={styles.title} style={{ color: value ? '#376F62' : '#999BB4' }}>{title}: {value ? 'Да' : 'Нет'}</p>
    </div>
  );
};

export default OrderAdditives;
